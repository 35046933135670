import Avatar from "@mui/material/Avatar";
import { useEffect, useState, useRef } from "react";
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import { useNavigate } from "react-router-dom";
import { Popover, IconButton } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CameraEnhanceOutlinedIcon from "@mui/icons-material/CameraEnhanceOutlined";
import { green } from "@mui/material/colors";

function Account() {
  const [userData, setUserData] = useState(null);
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [isLoading, set_isLoading] = useState(true);
  const [cancel_processing, set_cancel_processing] = useState(false);
  const navigator = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);
  const [image, setImage] = useState(null);

  const avatarUploadRef = useRef();

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open_popover = Boolean(anchorEl);

  useEffect(() => {
    set_isLoading(true);
    const fetchUserData = async () => {
      const response = await fetch(
        "https://ailessonplan.com/core/api/account",
        {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      const data = await response.json();
      setUserData(data);
      set_isLoading(false);
    };
    fetchUserData();
  }, []);

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handle_on_end_subscription = () => {
    if (cancel_processing) return;
    set_cancel_processing(true);
    fetch("https://ailessonplan.com/core/api/end_subscription", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle the response data
        //console.log(data);
        set_cancel_processing(false);
        setOpen(false);
        window.location.reload();
      })
      .catch((error) => {
        // Handle any errors
        //console.error(error);
      });
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setImage(imageUrl);
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {cancel_processing
            ? "Cancelling please wait..."
            : "Subscription Cancel Confirm"}
        </DialogTitle>
        <DialogContent>
          {cancel_processing ? (
            <>
              <Box sx={{ justifyContent: "center", display: "flex" }}>
                <CircularProgress />
              </Box>
            </>
          ) : (
            <>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to stop your subscription? next time you
                will need to renew the subscription manually in order to
                continue use our service.
              </DialogContentText>
            </>
          )}
        </DialogContent>
        <DialogActions>
          {cancel_processing ? (
            <></>
          ) : (
            <>
              <Button onClick={handleClose}>No, Go back</Button>
              <Button onClick={handle_on_end_subscription} autoFocus>
                Yes Cancel
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>

      {isLoading ? (
        <>
          <Box sx={{ justifyContent: "center", display: "flex", margin: 10 }}>
            <CircularProgress />
          </Box>
        </>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#f1f0ee",
              minHeight: "calc(100vh - 90px)",
            }}
          >
            <Card sx={{ maxWidth: "md", width: "100%", m: 3 }}>
              <CardContent sx={{ display: "flex", flexDirection: "column" }}>
                <Box
                  display="flex"
                  flexDirection={{ md: "row", xs: "column" }}
                  alignItems="center"
                  bgcolor="aliceblue"
                  p={3}
                  borderRadius={5}
                >
                  <Stack
                    sx={{
                      mb: 2,
                      justifyContent: "center",
                      alignItems: "center",
                      position: "relative",
                    }}
                  >
                    <Avatar
                      src={
                        image !== null
                          ? image
                          : "/assets/images/profile/Man.png"
                      }
                      alt="avatar"
                      sx={{ width: 100, height: 100, alignSelf: "center" }}
                    />
                    <input
                      type="file"
                      ref={avatarUploadRef}
                      onChange={handleImageChange}
                      hidden
                    />
                    <IconButton
                      size="medium"
                      onClick={() => {
                        avatarUploadRef.current.click();
                      }}
                      sx={{ position: "absolute" }}
                    >
                      <CameraEnhanceOutlinedIcon />
                    </IconButton>
                  </Stack>

                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems={{ md: "flex-start", xs: "center" }}
                    justifyContent="center"
                    ml={2}
                  >
                    <Typography variant="h5" fontWeight="bold">
                      {userData.user.name}
                    </Typography>
                    {userData.user.email_verified_at && (
                      <>
                        <IconButton
                          size="small"
                          onMouseEnter={handlePopoverOpen}
                          onMouseLeave={handlePopoverClose}
                        >
                          <CheckCircleIcon sx={{ color: green[500], ml: 1 }} />
                        </IconButton>
                        <Popover
                          open={open_popover}
                          anchorEl={anchorEl}
                          onClose={handlePopoverClose}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                          }}
                        >
                          <Box p={2}>
                            <Typography>Verified User</Typography>
                          </Box>
                        </Popover>
                      </>
                    )}
                    <Typography variant="body1" sx={{ mb: 2 }}>
                      {userData.user.email}
                    </Typography>
                  </Box>
                </Box>

                <Stack
                  spacing={2}
                  p={3}
                  mt={2}
                  borderRadius={2}
                  boxShadow="1px 1px 4px 0px rgba(0, 0, 0, 0.25)"
                >
                  <Typography variant="h6" fontWeight="bold" sx={{ mt: 1 }}>
                    My Plan
                  </Typography>
                  <Divider />
                  {userData.plan === null ? (
                    <>
                      <Typography>No Active plan yet.</Typography>
                      <Button
                        sx={{
                          marginTop: "10px",
                          textTransform: "none",
                          fontSize: "18px",
                        }}
                        onClick={() => {
                          navigator("/pricing");
                        }}
                      >
                        Subscribe to one of our plans to generate more Lessons.
                      </Button>
                    </>
                  ) : (
                    <>
                      <Box>
                        <Typography>Plan Name</Typography>
                        <TextField
                          variant="outlined"
                          fullWidth
                          margin="normal"
                          InputProps={{
                            readOnly: true,
                          }}
                          value={userData.plan.plane_name}
                          sx={{ mt: 0 }}
                          size="small"
                        />
                      </Box>
                      <Stack
                        direction={{ md: "row", xs: "column" }}
                        spacing={2}
                      >
                        <Box width={{ md: "50%", xs: "100%" }}>
                          <Typography>Plan Start Date</Typography>
                          <TextField
                            type="date"
                            variant="outlined"
                            margin="normal"
                            sx={{ mt: 0, width: "100%" }}
                            size="small"
                            InputProps={{
                              readOnly: true,
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            value={userData.plan.created_at.substring(
                              0,
                              userData.plan.created_at.indexOf(" ")
                            )}
                          />
                        </Box>
                        <Box width={{ md: "50%", xs: "100%" }}>
                          <Typography>Plan End Date</Typography>
                          {userData.plan.plane_name === "Life-Time" ? (
                            <></>
                          ) : (
                            <TextField
                              type="date"
                              variant="outlined"
                              margin="normal"
                              sx={{ mt: 0, width: "100%" }}
                              size="small"
                              InputProps={{
                                readOnly: true,
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              value={userData.plan.end_at}
                            />
                          )}
                        </Box>
                      </Stack>

                      {userData.plan.plane_name === "Life-Time" ? (
                        <>
                          <Card sx={{ padding: 2, background: "green" }}>
                            <Typography
                              sx={{
                                color: "white",
                                fontWeight: "bold",
                                textAlign: "center",
                              }}
                            >
                              Never Expire
                            </Typography>
                          </Card>
                        </>
                      ) : userData.plan.renew == "0" ? (
                        <Card sx={{ padding: 2, background: "red" }}>
                          <Typography
                            sx={{
                              color: "white",
                              fontWeight: "bold",
                              textAlign: "center",
                            }}
                          >
                            You will need to renew your subscription manually.
                          </Typography>
                        </Card>
                      ) : (
                        <>
                          <Button
                            variant="contained"
                            color="error"
                            sx={{ mt: 2 }}
                            onClick={() => {
                              setOpen(true);
                            }}
                          >
                            Cancel Subscription
                          </Button>
                        </>
                      )}
                    </>
                  )}
                </Stack>
              </CardContent>
            </Card>
          </div>
        </>
      )}
    </>
  );
}

export default Account;
