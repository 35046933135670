import React from 'react';
import { Box, Typography } from '@mui/material';
import AutorenewIcon from '@mui/icons-material/Autorenew';

const LoadingComponent = () => {
    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            height="100vh"
            width="100%"
        >
            <AutorenewIcon
                sx={{
                    fontSize: 60,
                    animation: 'spin 2s linear infinite',
                    '@keyframes spin': {
                        '0%': { transform: 'rotate(0deg)' },
                        '100%': { transform: 'rotate(360deg)' }
                    }
                }}
            />
            <Typography variant="h6" sx={{ marginTop: 2 }}>
                Loading...
            </Typography>
        </Box>
    );
};

export default LoadingComponent;