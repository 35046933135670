import {useEffect, useState} from "react";

function GenerateLessonMessage() {
    const messages = [
        'Please wait while generating the new Lesson...',
        'This may take 40 to 70 seconds depending on the lesson time.',
        'Did you know? Using AI to generate lessons can save teachers hours every week!',
        'Our AI analyzes millions of data points to create engaging and effective lessons.',
        'Almost There...',
        'Fact: AI can help customize lessons for individual student needs.',
        'Imagine all the time you\'ll save and the customization you can offer your students.',
        'With AI, lesson planning becomes a breeze!',
        'Your lesson is being tailored with the latest educational insights.',
        'Thank you for empowering your classroom with AI.'
    ];
    const [currentMessageIndex, setCurrentMessageIndex] = useState(0);

    function getRandomIndex() {
        return Math.floor(Math.random() * messages.length);
    }

    useEffect(() => {
        const interval = setInterval(() => {
            let newIndex;
            do {
                newIndex = getRandomIndex();
            } while (newIndex === currentMessageIndex); // Ensures the new random index isn't the same as the current one

            setCurrentMessageIndex(newIndex);
        }, 3000);

        return () => {
            clearInterval(interval);
        };
    }, [currentMessageIndex, messages.length]);

    return <div>{messages[currentMessageIndex]}</div>;
}

export default GenerateLessonMessage;