import {
  Stack,
  Box,
  Typography,
  Container,
  TextField,
  Button,
} from "@mui/material";
import React from "react";

const Support = () => {
  const [requestInfo, setRequestInfo] = React.useState({
    fullname: "",
    email: "",
    content: "",
  });

  return (
    <Container maxWidth="xl">
      <Stack>
        <Box bgcolor="#e6ebeb">
          <Typography
            variant="h4"
            textAlign="center"
            sx={{ color: "#5375E2" }}
            mt={7}
          >
            How can we help?
          </Typography>
          <Typography textAlign="center" mt={2}>
            Do you need support or have an suggestion or comment you'd like to
            share?
          </Typography>
          <Typography textAlign="center" mb={7}>
            Contact Us Below
          </Typography>
        </Box>
        <Stack p={{ md: 5, xs: 0 }} py={{ md: 0, xs: 4 }} spacing={2}>
          <Stack direction={{ md: "row", xs: "column" }} spacing={4}>
            <Box width={"calc(100vw)" > 600 ? "50%" : "100%"}>
              <Typography variant="h6">Full Name</Typography>
              <TextField
                fullWidth
                placeholder="SamrVasaye"
                onChange={(e) =>
                  setRequestInfo({ ...requestInfo, fullname: e.target.value })
                }
              />
            </Box>
            <Box width={"calc(100vw)" > 600 ? "50%" : "100%"}>
              <Typography variant="h6">Email Address</Typography>
              <TextField
                type="email"
                fullWidth
                placeholder="samrvasaye3875@gmail.com"
                onChange={(e) =>
                  setRequestInfo({ ...requestInfo, email: e.target.value })
                }
              />
            </Box>
          </Stack>
          <Box
            component="textarea"
            bgcolor="#e6ebeb"
            p={3}
            width="100%"
            height={256}
            placeholder="Type your message..."
            borderRadius={2}
            onChange={(e) =>
              setRequestInfo({ ...requestInfo, content: e.target.value })
            }
          />
          <Box display="flex" justifyContent="flex-end">
            <Button variant="contained" sx={{ px: 5, py: 1 }}>
              Submit
            </Button>
          </Box>
        </Stack>
      </Stack>
    </Container>
  );
};

export default Support;
