import React, { useEffect, useState } from 'react';
import {
    Dialog, DialogTitle, DialogContent, DialogActions, Button, RadioGroup, FormControlLabel,
    Radio, TextField, Typography, Divider, useTheme, CircularProgress, Snackbar, FormHelperText
} from '@mui/material';
import FeedbackIcon from '@mui/icons-material/Feedback';
import MuiAlert from '@mui/material/Alert';

const FeedbackDialog = () => {
    const [open, setOpen] = useState(false);
    const [quality, setQuality] = useState('');
    const [metExpectations, setMetExpectations] = useState('');
    const [suggestions, setSuggestions] = useState('');
    const [loading, setLoading] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [error, setError] = useState({});
    const theme = useTheme();
    const [token, setToken] = useState(localStorage.getItem("token"));

    useEffect(() => {
        // Check if the user has already provided feedback on startup
        fetch('https://ailessonplan.com/core/api/already_feedback', {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(res => res.json())
            .then(data => {
                if (data.message === false) {
                    setOpen(true);
                }
            });
    }, []);

    const validateForm = () => {
        let newError = {};

        if (!quality) newError.quality = 'Please select a rating for quality.';
        if (!metExpectations) newError.metExpectations = 'Please indicate if the lesson met your expectations.';

        setError(newError);

        return Object.keys(newError).length === 0;
    };

    const handleSubmit = async () => {
        if (validateForm()) {
            setLoading(true);
            // Make an API call to submit feedback
            const formData = new URLSearchParams();
            formData.append('quality', quality);
            formData.append('exp', metExpectations);
            formData.append('comment', suggestions.length === 0 ? "no comment" : suggestions);

            fetch('https://ailessonplan.com/core/api/feedback', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': `Bearer ${token}`
                },
                body: formData
            })
                .then(() => {
                    setLoading(false);
                    setOpen(false);
                    setSnackbarOpen(true);
                });
        }
    };


    return (
        <>
            <Dialog open={open} aria-labelledby="feedback-dialog-title">
                <DialogTitle id="feedback-dialog-title" style={{ fontWeight: 'bold' }}>
                    <FeedbackIcon color="primary" style={{ marginRight: theme.spacing(1) }} />
                    We'd Love Your Feedback
                </DialogTitle>
                <Divider />
                <DialogContent>
                    {/* Quality Question */}
                    <Typography variant="subtitle1" gutterBottom style={{ marginTop: theme.spacing(2), fontWeight: 'bold' }}>
                        How would you rate the quality of the lesson plan?
                    </Typography>
                    <RadioGroup
                        value={quality}
                        onChange={(e) => setQuality(e.target.value)}
                    >
                        <FormControlLabel value="Excellent" control={<Radio color="primary" />} label="Excellent" />
                        <FormControlLabel value="Good" control={<Radio color="primary" />} label="Good" />
                        <FormControlLabel value="Average" control={<Radio color="primary" />} label="Average" />
                        <FormControlLabel value="Poor" control={<Radio color="primary" />} label="Poor" />
                    </RadioGroup>
                    {error.quality && <FormHelperText error>{error.quality}</FormHelperText>}

                    {/* Expectations Question */}
                    <Typography variant="subtitle1" gutterBottom style={{ marginTop: theme.spacing(2), fontWeight: 'bold' }}>
                        Did the lesson plan meet your expectations?
                    </Typography>
                    <RadioGroup
                        value={metExpectations}
                        onChange={(e) => setMetExpectations(e.target.value)}
                    >
                        <FormControlLabel value="Yes" control={<Radio color="primary" />} label="Yes" />
                        <FormControlLabel value="No" control={<Radio color="primary" />} label="No" />
                    </RadioGroup>
                    {error.metExpectations && <FormHelperText error>{error.metExpectations}</FormHelperText>}

                    {/* Suggestions */}
                    <Typography variant="subtitle1" gutterBottom style={{ marginTop: theme.spacing(2), fontWeight: 'bold' }}>
                        Any suggestions or comments?
                    </Typography>
                    <TextField
                        variant="outlined"
                        multiline
                        rows={4}
                        fullWidth
                        value={suggestions}
                        onChange={(e) => setSuggestions(e.target.value)}
                        placeholder="Share your thoughts..."
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleSubmit} color="primary" variant="contained" endIcon={loading ? <CircularProgress size={20} /> : null}>
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={() => setSnackbarOpen(false)}
            >
                <MuiAlert elevation={6} variant="filled" severity="success">
                    Thank you for your feedback!
                </MuiAlert>
            </Snackbar>
        </>
    );
};

export default FeedbackDialog;