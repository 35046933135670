import * as React from "react";
import { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { Helmet } from "react-helmet";
import { Button, useMediaQuery } from "@mui/material";

function PricingTab() {
  const [already_used_trial, set_already_used_trial] = useState(false);
  const [start_free_trial, set_start_free_trial] = useState(false);
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [annuel_or_month, set_annuel_or_month] = useState(0);
  const [stripe, setStripe] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [userData, setUserData] = useState(null);

  const isMobile = useMediaQuery("(max-width:600px)");

  /*useEffect(() => {
        // Wait for all scripts to load before initializing Stripe
        window.addEventListener('load', () => {
            const stripePromise = window.Stripe('pk_test_51MsmfyKcZciro7XgDiA5icJF9WkyPXsalpp9hjTnMsIW3MVHjDgjOdDx7pORdcdQmiEUhBMV7RX2iPjunsRTtNN100ECNRCuRp');
            setStripe(stripePromise);
        });
    }, []);*/

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.stripe.com/v3/";
    script.addEventListener("load", () => {
      const stripePromise = window.Stripe(
        "pk_live_51MsmfyKcZciro7Xgn9VGKAa4cBEwQVxL1ZJEJtnZTlsU7ikQ0fTJRFmjFstfTYmW6pWE9d7EfSgyYEAux6Pa7b2100ozVqyPNW"
      );
      setStripe(stripePromise);
    });
    document.body.appendChild(script);

    const fetchUserData = async () => {
      const response = await fetch(
        "https://ailessonplan.com/core/api/account",
        {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      const data = await response.json();
      //console.log("data: ", data);
      setUserData(data);
    };
    fetchUserData();
  }, []);

  function StartActiveTrial() {
    fetch("https://ailessonplan.com/core/api/active_trial", {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((data) => parse_active_free_trial(data))
      .catch((error) => console.error(error));
  }

  function parse_active_free_trial(data) {
    //console.log('active trial: ',data);
    if (data.hasOwnProperty("message")) {
      //set_start_free_trial(true)
      set_already_used_trial(true);
      return;
    }
    set_start_free_trial(false);
  }

  const handleCheckout = async (type, plan) => {
    if (isProcessing) return;
    if (stripe == null) {
      console.log("Stripe is null");
      return;
    }
    setIsProcessing(true);
    const response = await fetch(
      "https://ailessonplan.com/core/api/create-checkout-session",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + token,
          Accept: "application/json",
        },
        body: new URLSearchParams({
          type: type,
          plan: plan,
          user_id: userData.user.id,
        }).toString(),
        //redirect: 'follow'
      }
    );

    const j = await response.json();
    const { sessionId } = j;
    //console.log("reply: ", sessionId);
    setIsProcessing(false);

    // Redirect to the Stripe checkout page with the session ID
    stripe.redirectToCheckout({ sessionId });
  };

  return (
    <>
      <Helmet>
        <script src="https://js.stripe.com/v3/"></script>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-M8647N6J28"
        ></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-M8647N6J28');
          `}
        </script>
      </Helmet>
      {/*Already used*/}
      <Dialog
        open={already_used_trial}
        onClose={() => {
          set_already_used_trial(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Already used"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You already on a Free trial
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <div>
        <section className="md:px-6 xs:px-0">
          <div className="mt-10 mx-auto container max-w-screen-2xl">
            <div className="flex flex-col lg:items-center justify-center w-full bg-[#e6ebeb] md:p-8 xs:p-4">
              <p className="font-semibold text-[#5375E2] text-2xl md:text-4xl text-center">
                Ready To Cut Hours Off Your Work Week?
              </p>
              <p className="mt-2.5 lg:w-1/2 text-center text-xl md:text-2xl">
                For a limited time, you can lock in special launch pricing for
                the Life of your account
              </p>
            </div>
            <div className="flex items-center justify-center w-full">
              <div className="pt-10">
                <div className="mx-auto">
                  <div className="flex flex-col items-center xl:w-4/5 w-11/12 mx-auto mb-12">
                    <div className={isMobile ? "flex mt-3" : "mt-3"}>
                      <Button
                        variant={
                          annuel_or_month === 0 ? "outlined" : "contained"
                        }
                        onClick={() => set_annuel_or_month(1)}
                        size="large"
                      >
                        Bill Monthly
                      </Button>
                      <Button
                        variant={
                          annuel_or_month === 0 ? "contained" : "outlined"
                        }
                        onClick={() => set_annuel_or_month(0)}
                        size="large"
                      >
                        Bill Yearly
                      </Button>
                    </div>
                  </div>
                  {annuel_or_month === 0 ? (
                    /*yearly payment is here*/
                    <>
                      <div className="flex flex-wrap mb-12 justify-between sm:justify-center -mx-6">
                        <div className="w-full xl:w-1/3 lg:w-1/3 md:w-1/2 sm:w-1/2 xs:w-full mb-4 px-6">
                          <div className="flex flex-col bg-white h-full border border-gray-200 shadow rounded-lg text-left">
                            <div>
                              <h4 className="w-full text-4xl text-indigo-500 text-center rounded-md border font-semibold p-4">
                                Free Trial
                              </h4>
                            </div>
                            <ul className="flex flex-col mb-6 px-4 py-5 grow">
                              <li className="text-center mb-2.5">
                                <p className="text-lg font-bold">
                                  No{" "}
                                  <code className="text-blue-600">
                                    Credit Card
                                  </code>
                                  <br /> Required
                                </p>
                              </li>
                              <hr />
                              <li className="flex items-center justify-between mt-3 mb-2.5">
                                <p className="text-gray-800 text-base font-normal">
                                  7-Day FREE Trial
                                </p>
                                <img
                                  src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png"
                                  alt="check-mark"
                                />
                              </li>
                              <li className="flex items-center justify-between mb-2.5">
                                <p className="text-gray-800 text-base font-normal">
                                  Create 1 Week’s worth of lessons and
                                  Additional Resources
                                </p>
                                <img
                                  src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png"
                                  alt="check-mark"
                                />
                              </li>
                              <li className="flex items-center justify-between mb-2.5">
                                <p className="text-gray-800 text-base font-normal">
                                  Access to 100+ AI Education Modules
                                </p>
                                <img
                                  src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png"
                                  alt="check-mark"
                                />
                              </li>
                              <li className="flex items-center justify-between mb-2.5">
                                <p className="text-gray-800 text-base font-normal">
                                  No Software to Install
                                </p>
                                <img
                                  src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png"
                                  alt="check-mark"
                                />
                              </li>
                              <li className="flex items-center justify-between mb-2.5">
                                <p className="text-gray-800 text-base font-normal">
                                  Export to PDF or Word Doc
                                </p>
                                <img
                                  src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png"
                                  alt="check-mark"
                                />
                              </li>
                              {/* <li className="flex items-center mb-2.5">
                                                                    <p className="text-gray-400 text-base font-normal">Specialists appoinments</p>
                                                                    <img src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png" className="opacity-0 mr-4" alt="check-mark" />
                                                                    </li> */}
                            </ul>
                            <p className="flex justify-center items-center text-base text-indigo-500 relative pl-3">
                              <span className="font-light text-lg">$</span>
                              <span className="text-2xl font-semibold">0</span>
                              <span className="text-gray-600 font-light text-lg">
                                /month
                              </span>
                            </p>
                            <button
                              onClick={StartActiveTrial}
                              className="m-5 text-2xl rounded-md p-3 bg-gray-200 hover:bg-gray-300 focus:outline-none transition duration-150 ease-in-out rounded text-indigo-500 font-semibold "
                            >
                              Try It FREE Now!
                            </button>
                          </div>
                        </div>
                        <div className="w-full xl:w-1/3 lg:w-1/3 md:w-1/2 sm:w-1/2 mb-4 px-6">
                          <div className="flex flex-col bg-indigo-700 h-full border border-gray-200 shadow rounded-lg text-left">
                            <div>
                              <h4 className="text-4xl text-white text-center rounded-md border font-semibold p-4">
                                Educator
                              </h4>
                            </div>
                            <ul className="flex flex-col mb-6 px-4 py-5 grow">
                              <li className="flex items-center">
                                <h5 className="text-2xl text-white text-center font-semibold pb-6">
                                  SAVE 68% (Normally $708/yr) Paid Yearly for
                                  average $19/MO
                                </h5>
                              </li>
                              <hr className="mb-3" />
                              <li className="flex items-center justify-between mt-3 mb-2.5">
                                <p className="text-white text-base font-normal">
                                  Access to All Lesson Plan Formats
                                </p>
                                <img
                                  src="https://cdn.tuk.dev/assets/templates/weCare/checkMarkWhite.png"
                                  alt="check-mark"
                                />
                              </li>
                              <li className="flex items-center justify-between mb-2.5">
                                <p className="text-white text-base font-normal">
                                  Access to 100+ AI Education Modules
                                </p>
                                <img
                                  src="https://cdn.tuk.dev/assets/templates/weCare/checkMarkWhite.png"
                                  alt="check-mark"
                                />
                              </li>
                              <li className="flex items-center justify-between mb-2.5">
                                <p className="text-white text-base font-normal">
                                  No Software to Install
                                </p>
                                <img
                                  src="https://cdn.tuk.dev/assets/templates/weCare/checkMarkWhite.png"
                                  alt="check-mark"
                                />
                              </li>
                              <li className="flex items-center justify-between mb-2.5">
                                <p className="text-white text-base font-normal">
                                  Export to PDF or Word Doc
                                </p>
                                <img
                                  src="https://cdn.tuk.dev/assets/templates/weCare/checkMarkWhite.png"
                                  alt="check-mark"
                                />
                              </li>
                              <li className="flex items-center justify-between mb-2.5">
                                <p className="text-white text-base font-normal">
                                  Access to Educator Member
                                </p>
                                <img
                                  src="https://cdn.tuk.dev/assets/templates/weCare/checkMarkWhite.png"
                                  alt="check-mark"
                                />
                              </li>
                              <li className="flex items-center justify-between mb-2.5">
                                <p className="text-white text-base font-normal">
                                  Rewards Portal with additional resources,
                                  templates, and exclusive.Travel Incentives and
                                  Discounts for Educators
                                </p>
                                <img
                                  src="https://cdn.tuk.dev/assets/templates/weCare/checkMarkWhite.png"
                                  alt="check-mark"
                                />
                              </li>
                              <li className="flex items-center justify-between mb-2.5">
                                <p className="text-white text-base font-normal">
                                  All Future Upgrades and Features!
                                </p>
                                <img
                                  src="https://cdn.tuk.dev/assets/templates/weCare/checkMarkWhite.png"
                                  alt="check-mark"
                                />
                              </li>
                            </ul>
                            <p className="flex justify-center items-center text-base text-white relative pl-3">
                              <span className="font-light text-lg">$</span>
                              <span className="text-2xl font-semibold">
                                229
                              </span>
                              <span className="font-light text-lg">/year</span>
                            </p>
                            <button
                              onClick={() => {
                                handleCheckout("year", "basic");
                              }}
                              className="m-5 rounded-md p-3 text-indigo-500 focus:outline-none transition duration-150 ease-in-out rounded bg-white hover:bg-gray-100  text-2xl font-semibold"
                            >
                              Get This Deal!
                            </button>
                          </div>
                        </div>
                        <div className="w-full xl:w-1/3 lg:w-1/3 md:w-1/2 sm:w-1/2 mb-4 px-6">
                          <div className="flex flex-col h-full bg-white border border-gray-200shadow rounded-lg text-left">
                            <h4 className="text-4xl text-indigo-500 text-center rounded-md border font-semibold p-4">
                              Pay Once
                            </h4>
                            <ul className="flex flex-col mb-6 py-5 px-4 grow">
                              <li className="flex items-center">
                                <h5 className="text-2xl text-indigo-500 text-center font-semibold pb-6">
                                  Save 70% Normally $1999 after
                                </h5>
                              </li>
                              <hr className="mb-3" />
                              <li className="flex items-center justify-between mt-3 mb-2.5">
                                <p className="text-gray-800 text-base font-normal">
                                  One Payment only, no Recurring Payments ever!
                                </p>
                                <img
                                  src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png"
                                  alt="check-mark"
                                />
                              </li>
                              <li className="flex items-center justify-between mb-2.5">
                                <p className="text-gray-800 text-base font-normal">
                                  Access to All Lessons Plan Formats
                                </p>
                                <img
                                  src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png"
                                  alt="check-mark"
                                />
                              </li>
                              <li className="flex items-center justify-between mb-2.5">
                                <p className="text-gray-800 text-base font-normal">
                                  Access to 100+ AI Education Modules
                                </p>
                                <img
                                  src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png"
                                  alt="check-mark"
                                />
                              </li>
                              <li className="flex items-center justify-between mb-2.5">
                                <p className="text-gray-800 text-base font-normal">
                                  No Software to Install
                                </p>
                                <img
                                  src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png"
                                  alt="check-mark"
                                />
                              </li>
                              <li className="flex items-center justify-between mb-2.5">
                                <p className="text-gray-800 text-base font-normal">
                                  Export to PDF or Word Doc
                                </p>
                                <img
                                  src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png"
                                  alt="check-mark"
                                />
                              </li>
                              <li className="flex items-center justify-between mb-2.5">
                                <p className="text-gray-800 text-base font-normal">
                                  Access to Educator Member
                                </p>
                                <img
                                  src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png"
                                  alt="check-mark"
                                />
                              </li>
                              <li className="flex items-center justify-between mb-2.5">
                                <p className="text-gray-800 text-base font-normal">
                                  Rewards Portal with additional resources,
                                  templates, and exclusive.Travel Incentives and
                                  Discounts for Educators
                                </p>
                                <img
                                  src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png"
                                  alt="check-mark"
                                />
                              </li>
                              <li className="flex items-center justify-between mb-2.5">
                                <p className="text-gray-800 text-base font-normal">
                                  All Future Upgrades and Features!
                                </p>
                                <img
                                  src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png"
                                  alt="check-mark"
                                />
                              </li>
                            </ul>
                            <p className="flex justify-center items-center text-base text-indigo-500 relative pl-3">
                              <span className="font-light text-lg">$</span>
                              <span className="text-2xl font-semibold">
                                599
                              </span>
                              <span className="font-light text-lg">
                                &nbsp;one-time
                              </span>
                            </p>
                            <button
                              onClick={() => {
                                handleCheckout("year", "one-time");
                              }}
                              className="m-5 rounded-md p-3 bg-gray-200 hover:bg-gray-300 focus:outline-none transition duration-150 ease-in-out rounded text-indigo-500  text-2xl font-semibold"
                            >
                              Get This Deal!
                            </button>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="flex flex-wrap mb-12 justify-between sm:justify-center -mx-6">
                        <div className="w-full xl:w-1/3 lg:w-1/3 md:w-1/2 sm:w-1/2 mb-4 px-6">
                          <div className="flex flex-col h-full bg-white border border-gray-200shadow rounded-lg text-left">
                            <h4 className="text-4xl text-indigo-500 text-center rounded-md border font-semibold p-4">
                              Free Trial
                            </h4>
                            <ul className="flex flex-col mb-6 py-5 px-4 grow">
                              <li className="flex items-center justify-between mb-2.5">
                                <p className="text-gray-800 text-base font-normal">
                                  5 Custom Class Profile
                                </p>
                                <img
                                  src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png"
                                  alt="check-mark"
                                />
                              </li>
                              <li className="flex items-center justify-between mb-2.5">
                                <p className="text-gray-800 text-base font-normal">
                                  5 Lesson plan one-time for one week
                                </p>
                                <img
                                  src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png"
                                  alt="check-mark"
                                />
                              </li>
                              <li className="flex items-center justify-between mb-2.5">
                                <p className="text-gray-800 text-base font-normal">
                                  25 Additional Resources Included (HomeWork,
                                  WorkSheets, Rubrics, Assessments)
                                </p>
                                <img
                                  src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png"
                                  alt="check-mark"
                                />
                              </li>
                            </ul>
                            <p className="flex justify-center items-center text-base text-indigo-500 relative pl-3">
                              <span className="font-light text-lg">$</span>
                              <span className="text-2xl font-semibold">0</span>
                              <span className="text-gray-600 font-light text-lg">
                                /month
                              </span>
                            </p>
                            <button
                              onClick={StartActiveTrial}
                              className="m-5 rounded-md p-3 bg-gray-200 hover:bg-gray-300 focus:outline-none transition duration-150 ease-in-out rounded text-indigo-500  text-2xl font-semibold"
                            >
                              Try It FREE Now!
                            </button>
                          </div>
                        </div>
                        <div className="w-full xl:w-1/3 lg:w-1/3 md:w-1/2 sm:w-1/2 mb-4 px-6">
                          <div className="flex flex-col h-full bg-indigo-700 border border-gray-200 shadow rounded-lg text-left">
                            <h4 className="text-4xl text-white text-center rounded-md border font-semibold p-4">
                              Educator
                            </h4>
                            <ul className="flex flex-col mb-6 py-5 px-4 grow">
                              <li className="flex items-center justify-between mb-2.5">
                                <p className="text-white text-base font-normal">
                                  5 Custom Class Profile
                                </p>
                                <img
                                  src="https://cdn.tuk.dev/assets/templates/weCare/checkMarkWhite.png"
                                  alt="check-mark"
                                />
                              </li>
                              <li className="flex items-center justify-between mb-2.5">
                                <p className="text-white text-base font-normal">
                                  25 Lesson Plans Per Month
                                </p>
                                <img
                                  src="https://cdn.tuk.dev/assets/templates/weCare/checkMarkWhite.png"
                                  alt="check-mark"
                                />
                              </li>
                              <li className="flex items-center justify-between mb-2.5">
                                <p className="text-white text-base font-normal">
                                  25 Additional Resources Included (HomeWork,
                                  WorkSheets, Rubrics, Assessments, ..etc)
                                </p>
                                <img
                                  src="https://cdn.tuk.dev/assets/templates/weCare/checkMarkWhite.png"
                                  alt="check-mark"
                                />
                              </li>
                              <li className="flex items-center justify-between mb-2.5">
                                <p className="text-white text-base font-normal">
                                  Access to all lesson plan formats
                                </p>
                                <img
                                  src="https://cdn.tuk.dev/assets/templates/weCare/checkMarkWhite.png"
                                  alt="check-mark"
                                />
                              </li>
                              <li className="flex items-center justify-between mb-2.5">
                                <p className="text-white text-base font-normal">
                                  All Features included
                                </p>
                                <img
                                  src="https://cdn.tuk.dev/assets/templates/weCare/checkMarkWhite.png"
                                  alt="check-mark"
                                />
                              </li>
                              <li className="flex items-center justify-between mb-2.5">
                                <p className="text-white text-base font-normal">
                                  No Software install needed
                                </p>
                                <img
                                  src="https://cdn.tuk.dev/assets/templates/weCare/checkMarkWhite.png"
                                  alt="check-mark"
                                />
                              </li>
                              <li className="flex items-center justify-between mb-2.5">
                                <p className="text-white text-base font-normal">
                                  Cloud Based generative AI
                                </p>
                                <img
                                  src="https://cdn.tuk.dev/assets/templates/weCare/checkMarkWhite.png"
                                  alt="check-mark"
                                />
                              </li>
                            </ul>
                            <p className="flex justify-center items-center text-base text-white relative pl-3">
                              <span className="font-light text-lg">$</span>
                              <span className="text-2xl font-semibold">29</span>
                              <span className="font-light text-lg">/month</span>
                            </p>
                            <button
                              onClick={() => {
                                handleCheckout("month", "basic");
                              }}
                              className="m-5 rounded-md p-3 text-indigo-500 focus:outline-none transition duration-150 ease-in-out rounded bg-white hover:bg-gray-100  text-2xl font-semibold"
                            >
                              Get This Deal!
                            </button>
                          </div>
                        </div>
                        <div className="w-full xl:w-1/3 lg:w-1/3 md:w-1/2 sm:w-1/2 mb-4 px-6">
                          <div className="flex flex-col h-full bg-white border border-gray-200shadow rounded-lg text-left">
                            <h4 className="text-4xl text-indigo-500 text-center rounded-md border font-semibold p-4">
                              Pay Once
                            </h4>
                            <ul className="flex flex-col mb-6 py-5 px-4 grow">
                              <li className="flex items-center justify-between mb-2.5">
                                <p className="text-gray-800 text-base font-normal">
                                  10 Custom Class Profile
                                </p>
                                <img
                                  src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png"
                                  alt="check-mark"
                                />
                              </li>
                              <li className="flex items-center justify-between mb-2.5">
                                <p className="text-gray-800 text-base font-normal">
                                  25 Lesson Plans Per Month
                                </p>
                                <img
                                  src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png"
                                  alt="check-mark"
                                />
                              </li>
                              <li className="flex items-center justify-between mb-2.5">
                                <p className="text-gray-800 text-base font-normal">
                                  25 Additional Resources Included (HomeWork,
                                  WorkSheets, Rubrics, Assessments, ..etc)
                                </p>
                                <img
                                  src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png"
                                  alt="check-mark"
                                />
                              </li>
                              <li className="flex items-center justify-between mb-2.5">
                                <p className="text-gray-800 text-base font-normal">
                                  Access to all lesson plan formats
                                </p>
                                <img
                                  src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png"
                                  alt="check-mark"
                                />
                              </li>
                              <li className="flex items-center justify-between mb-2.5">
                                <p className="text-gray-800 text-base font-normal">
                                  All Features included
                                </p>
                                <img
                                  src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png"
                                  alt="check-mark"
                                />
                              </li>
                              <li className="flex items-center justify-between mb-2.5">
                                <p className="text-gray-800 text-base font-normal">
                                  No Software install needed
                                </p>
                                <img
                                  src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png"
                                  alt="check-mark"
                                />
                              </li>
                              <li className="flex items-center justify-between mb-2.5">
                                <p className="text-gray-800 text-base font-normal">
                                  Cloud based generative AI
                                </p>
                                <img
                                  src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png"
                                  alt="check-mark"
                                />
                              </li>
                              <li className="flex items-center justify-between mb-2.5">
                                <p className="text-gray-800 text-base font-normal">
                                  24/7 Support
                                </p>
                                <img
                                  src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png"
                                  alt="check-mark"
                                />
                              </li>
                            </ul>
                            <p className="flex justify-center items-center text-base text-indigo-500 relative pl-3">
                              <span className="font-light text-lg">$</span>
                              <span className="text-2xl font-semibold">
                                249
                              </span>
                              <span className="font-light text-lg">
                                /MO (3 times only)
                              </span>
                            </p>
                            <button
                              onClick={() => {
                                handleCheckout("month", "one-time");
                              }}
                              className="m-5 rounded-md p-3 bg-gray-200 hover:bg-gray-300 focus:outline-none transition duration-150 ease-in-out rounded text-indigo-500  text-2xl font-semibold"
                            >
                              Get This Deal!
                            </button>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <style
                  dangerouslySetInnerHTML={{
                    __html: "",
                  }}
                />
              </div>
            </div>
          </div>
        </section>

        <style>
          {` 
            .checkbox:checked {
                right: 0;
                background-color: #4338ca;
            }
            `}
        </style>
      </div>
    </>
  );
}

export default PricingTab;
