import HomeHeader from "../../components/Home/HomeHeader";
import { Outlet, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { get_auth_user, isAuthenticated } from "../Auth/auth";
import { CircularProgress } from "@mui/material";
import Box from "@mui/material/Box";
import Wizard from "../../components/Home/Wizard";
import EmailAddressNotVerified from "../../components/Auth/EmailAddressNotVerified";
import SpecialOffer from "../../components/offers/SpecialOffer";
import LoadingComponent from "../../components/LoadingComponents/LoadingComponent";
import FeedbackDialog from "../../components/Home/FeedbackDialog";
import NewHeader from "../../components/LandingPage/NewHeader";

function HomePage() {
  const [loading, setLoading] = useState(true);
  const [authenticated, setAuthenticated] = useState(false);

  const navigator = useNavigate();
  const [show_email_verification_alert, setShow_email_verification_alert] =
    useState(false);

  const [openFeedback, setOpenFeedback] = useState(true);
  function onClose() {
    setOpenFeedback(false);
  }

  useEffect(() => {
    async function checkAuth() {
      const auth = await isAuthenticated();
      setAuthenticated(!!auth);
      setLoading(false);
      if (auth) {
        //console.log(auth);
        if (auth.email_verified_at === null) {
          //console.log("Yes null");
          setShow_email_verification_alert(true);
        } else {
          //console.log("Not null");
        }
      }
    }
    checkAuth();
  }, []);

  if (loading) {
    return <LoadingComponent />;
  }
  return (
    <>
      {authenticated ? (
        <>
          <FeedbackDialog />
          <SpecialOffer />
          <Wizard />
          {/* <HomeHeader /> */}
          <NewHeader />
          {/*{
                            show_email_verification_alert ? (
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    height="6vh"
                                >
                                    <div className="">
                                        <EmailAddressNotVerified />
                                    </div>
                                </Box>
                            ) : (
                                <>
                                </>
                            )
                        }*/}
          <Outlet />
        </>
      ) : (
        navigator("/signing")
      )}
    </>
  );
}

export default HomePage;
