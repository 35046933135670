function Testimonials() {
    return(
      <>
          {/* ====== Testimonials Start ====== */}
          <section id="testimonials" className="pt-20 md:pt-[120px]">
              <div className="container px-4">
                  <div className="flex flex-wrap">
                      <div className="mx-4 w-full">
                          <div className="mx-auto mb-[60px] max-w-[620px] text-center lg:mb-20">
                  <span className="mb-2 block text-lg font-semibold text-primary">
                    Testimonials
                  </span>
                              <h2 className="mb-4 text-3xl font-bold text-dark sm:text-4xl md:text-[42px]">
                                  What Our Educators Are Saying
                              </h2>
                              <p className="text-lg leading-relaxed text-body-color sm:text-xl sm:leading-relaxed">
                                  Join the teachers that have already started creating amazing lesson plans for their students in a fraction of the time.
                              </p>
                          </div>
                      </div>
                  </div>
                  <div className="flex flex-wrap">
                      <div className="w-full px-4 md:w-1/2 lg:w-1/3">
                          <div className="ud-single-testimonial wow fadeInUp mb-12 bg-white p-8 shadow-testimonial" data-wow-delay=".1s
              ">
                              <div className="ud-testimonial-ratings mb-3 flex items-center">
                    <span className="mr-1 text-[#fbb040]">
                      <svg width={18} height={16} viewBox="0 0 18 16" className="fill-current">
                        <path d="M9.09815 0.360596L11.1054 6.06493H17.601L12.3459 9.5904L14.3532 15.2947L9.09815 11.7693L3.84309 15.2947L5.85035 9.5904L0.595291 6.06493H7.0909L9.09815 0.360596Z" />
                      </svg>
                    </span>
                                  <span className="mr-1 text-[#fbb040]">
                      <svg width={18} height={16} viewBox="0 0 18 16" className="fill-current">
                        <path d="M9.09815 0.360596L11.1054 6.06493H17.601L12.3459 9.5904L14.3532 15.2947L9.09815 11.7693L3.84309 15.2947L5.85035 9.5904L0.595291 6.06493H7.0909L9.09815 0.360596Z" />
                      </svg>
                    </span>
                                  <span className="mr-1 text-[#fbb040]">
                      <svg width={18} height={16} viewBox="0 0 18 16" className="fill-current">
                        <path d="M9.09815 0.360596L11.1054 6.06493H17.601L12.3459 9.5904L14.3532 15.2947L9.09815 11.7693L3.84309 15.2947L5.85035 9.5904L0.595291 6.06493H7.0909L9.09815 0.360596Z" />
                      </svg>
                    </span>
                                  <span className="mr-1 text-[#fbb040]">
                      <svg width={18} height={16} viewBox="0 0 18 16" className="fill-current">
                        <path d="M9.09815 0.360596L11.1054 6.06493H17.601L12.3459 9.5904L14.3532 15.2947L9.09815 11.7693L3.84309 15.2947L5.85035 9.5904L0.595291 6.06493H7.0909L9.09815 0.360596Z" />
                      </svg>
                    </span>
                                  <span className="mr-1 text-[#fbb040]">
                      <svg width={18} height={16} viewBox="0 0 18 16" className="fill-current">
                        <path d="M9.09815 0.360596L11.1054 6.06493H17.601L12.3459 9.5904L14.3532 15.2947L9.09815 11.7693L3.84309 15.2947L5.85035 9.5904L0.595291 6.06493H7.0909L9.09815 0.360596Z" />
                      </svg>
                    </span>
                              </div>
                              <div className="ud-testimonial-content mb-6">
                                  <p className="text-base tracking-wide text-body-color">
                                      “This really couldn't be easier.i set up my class profile once, use the textbook lesson plan module to create custom lesson plans for the exact pages i want to teach each day, all in seconds.
                                  </p>
                              </div>
                              <div className="ud-testimonial-info flex items-center">
                                  <div className="ud-testimonial-image mr-5 h-[50px] w-[50px] overflow-hidden rounded-full">
                                      <img src="assets/images/testimonials/author-01.png" alt="author" />
                                  </div>
                                  <div className="ud-testimonial-meta">
                                      <h4 className="text-sm font-semibold">Matt C.</h4>
                                      <p className="text-xs text-[#969696]">New Jersey</p>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div className="w-full px-4 md:w-1/2 lg:w-1/3">
                          <div className="ud-single-testimonial wow fadeInUp mb-12 bg-white p-8 shadow-testimonial" data-wow-delay=".15s
              ">
                              <div className="ud-testimonial-ratings mb-3 flex items-center">
                    <span className="mr-1 text-[#fbb040]">
                      <svg width={18} height={16} viewBox="0 0 18 16" className="fill-current">
                        <path d="M9.09815 0.360596L11.1054 6.06493H17.601L12.3459 9.5904L14.3532 15.2947L9.09815 11.7693L3.84309 15.2947L5.85035 9.5904L0.595291 6.06493H7.0909L9.09815 0.360596Z" />
                      </svg>
                    </span>
                                  <span className="mr-1 text-[#fbb040]">
                      <svg width={18} height={16} viewBox="0 0 18 16" className="fill-current">
                        <path d="M9.09815 0.360596L11.1054 6.06493H17.601L12.3459 9.5904L14.3532 15.2947L9.09815 11.7693L3.84309 15.2947L5.85035 9.5904L0.595291 6.06493H7.0909L9.09815 0.360596Z" />
                      </svg>
                    </span>
                                  <span className="mr-1 text-[#fbb040]">
                      <svg width={18} height={16} viewBox="0 0 18 16" className="fill-current">
                        <path d="M9.09815 0.360596L11.1054 6.06493H17.601L12.3459 9.5904L14.3532 15.2947L9.09815 11.7693L3.84309 15.2947L5.85035 9.5904L0.595291 6.06493H7.0909L9.09815 0.360596Z" />
                      </svg>
                    </span>
                                  <span className="mr-1 text-[#fbb040]">
                      <svg width={18} height={16} viewBox="0 0 18 16" className="fill-current">
                        <path d="M9.09815 0.360596L11.1054 6.06493H17.601L12.3459 9.5904L14.3532 15.2947L9.09815 11.7693L3.84309 15.2947L5.85035 9.5904L0.595291 6.06493H7.0909L9.09815 0.360596Z" />
                      </svg>
                    </span>
                                  <span className="mr-1 text-[#fbb040]">
                      <svg width={18} height={16} viewBox="0 0 18 16" className="fill-current">
                        <path d="M9.09815 0.360596L11.1054 6.06493H17.601L12.3459 9.5904L14.3532 15.2947L9.09815 11.7693L3.84309 15.2947L5.85035 9.5904L0.595291 6.06493H7.0909L9.09815 0.360596Z" />
                      </svg>
                    </span>
                              </div>
                              <div className="ud-testimonial-content mb-6">
                                  <p className="text-base tracking-wide text-body-color">
                                      “This really has been game-changing for me. I used to spend 10-15 hours a week on lesson planning, i've cut that down by 90% and with very minor changes my lesson plans are more complete and thought out than ever!
                                  </p>
                              </div>
                              <div className="ud-testimonial-info flex items-center">
                                  <div className="ud-testimonial-image mr-5 h-[50px] w-[50px] overflow-hidden rounded-full">
                                      <img src="assets/images/testimonials/author-02.png" alt="author" />
                                  </div>
                                  <div className="ud-testimonial-meta">
                                      <h4 className="text-sm font-semibold">Susan M.</h4>
                                      <p className="text-xs text-[#969696]">Tennessee</p>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div className="w-full px-4 md:w-1/2 lg:w-1/3">
                          <div className="ud-single-testimonial wow fadeInUp mb-12 bg-white p-8 shadow-testimonial" data-wow-delay=".2s
              ">
                              <div className="ud-testimonial-ratings mb-3 flex items-center">
                    <span className="mr-1 text-[#fbb040]">
                      <svg width={18} height={16} viewBox="0 0 18 16" className="fill-current">
                        <path d="M9.09815 0.360596L11.1054 6.06493H17.601L12.3459 9.5904L14.3532 15.2947L9.09815 11.7693L3.84309 15.2947L5.85035 9.5904L0.595291 6.06493H7.0909L9.09815 0.360596Z" />
                      </svg>
                    </span>
                                  <span className="mr-1 text-[#fbb040]">
                      <svg width={18} height={16} viewBox="0 0 18 16" className="fill-current">
                        <path d="M9.09815 0.360596L11.1054 6.06493H17.601L12.3459 9.5904L14.3532 15.2947L9.09815 11.7693L3.84309 15.2947L5.85035 9.5904L0.595291 6.06493H7.0909L9.09815 0.360596Z" />
                      </svg>
                    </span>
                                  <span className="mr-1 text-[#fbb040]">
                      <svg width={18} height={16} viewBox="0 0 18 16" className="fill-current">
                        <path d="M9.09815 0.360596L11.1054 6.06493H17.601L12.3459 9.5904L14.3532 15.2947L9.09815 11.7693L3.84309 15.2947L5.85035 9.5904L0.595291 6.06493H7.0909L9.09815 0.360596Z" />
                      </svg>
                    </span>
                                  <span className="mr-1 text-[#fbb040]">
                      <svg width={18} height={16} viewBox="0 0 18 16" className="fill-current">
                        <path d="M9.09815 0.360596L11.1054 6.06493H17.601L12.3459 9.5904L14.3532 15.2947L9.09815 11.7693L3.84309 15.2947L5.85035 9.5904L0.595291 6.06493H7.0909L9.09815 0.360596Z" />
                      </svg>
                    </span>
                                  <span className="mr-1 text-[#fbb040]">
                      <svg width={18} height={16} viewBox="0 0 18 16" className="fill-current">
                        <path d="M9.09815 0.360596L11.1054 6.06493H17.601L12.3459 9.5904L14.3532 15.2947L9.09815 11.7693L3.84309 15.2947L5.85035 9.5904L0.595291 6.06493H7.0909L9.09815 0.360596Z" />
                      </svg>
                    </span>
                              </div>
                              <div className="ud-testimonial-content mb-6">
                                  <p className="text-base tracking-wide text-body-color">
                                      “The lesson plans are great, but the additional resources save me a ton of time. I never have to worry about creating a worksheet or homework assignment again. I just tell it what I want, tweak around the edges and I'm done.
                                  </p>
                              </div>
                              <div className="ud-testimonial-info flex items-center">
                                  <div className="ud-testimonial-image mr-5 h-[50px] w-[50px] overflow-hidden rounded-full">
                                      <img src="assets/images/testimonials/author-03.png" alt="author" />
                                  </div>
                                  <div className="ud-testimonial-meta">
                                      <h4 className="text-sm font-semibold">Kelly B.</h4>
                                      <p className="text-xs text-[#969696]">Ohio</p>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  {/*<div className="flex flex-wrap">
                      <div className="mx-4 w-full">
                          <div className="wow fadeInUp" data-wow-delay=".2s">
                              <div className="ud-title mb-8">
                                  <h6 className="relative inline-flex items-center text-xs font-normal text-body-color">
                                      Some Of Our Clients
                                      <span className="ml-4 inline-block h-[1px] w-8 bg-[#afb2b5]">
                      </span>
                                  </h6>
                              </div>
                              <div className="ud-brands-logo flex flex-wrap items-center">
                                  <div className="ud-single-logo mr-10 mb-5 max-w-[140px]">
                                      <a href="https://tailgrids.com" target="_blank" rel="nofollow noopner">
                                          <img src="assets/images/brands/tailgrids.svg" alt="tailgrids" className="grayscale duration-300 hover:filter-none" />
                                      </a>
                                  </div>
                                  <div className="ud-single-logo mr-10 mb-5 max-w-[140px]">
                                      <a href="https://ayroui.com" target="_blank" rel="nofollow noopner">
                                          <img src="assets/images/brands/ayroui.svg" alt="ayroui" className="grayscale duration-300 hover:filter-none" />
                                      </a>
                                  </div>
                                  <div className="ud-single-logo mr-10 mb-5 max-w-[140px]">
                                      <a href="https://uideck.com" target="_blank" rel="nofollow noopner">
                                          <img src="assets/images/brands/uideck.svg" alt="uideck" className="grayscale duration-300 hover:filter-none" />
                                      </a>
                                  </div>
                                  <div className="ud-single-logo mr-10 mb-5 max-w-[140px]">
                                      <a href="https://graygrids.com" target="_blank" rel="nofollow noopner">
                                          <img src="assets/images/brands/graygrids.svg" alt="graygrids" className="grayscale duration-300 hover:filter-none" />
                                      </a>
                                  </div>
                                  <div className="ud-single-logo mr-10 mb-5 max-w-[140px]">
                                      <a href="https://lineicons.com" target="_blank" rel="nofollow noopner">
                                          <img src="assets/images/brands/lineicons.svg" alt="lineicons" className="grayscale duration-300 hover:filter-none" />
                                      </a>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>*/}
              </div>
          </section>
          {/* ====== Testimonials End ====== */}
      </>
    );
}

export default Testimonials;