import React, { useState } from "react";
import {
  Box,
  Button,
  Container,
  Stack,
  Tooltip,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  Avatar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Logout } from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";

const NewHeader = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width:768px)");
  const taglist = [
    {
      name: "My Content",
      url: "/lessons",
      type: "internal",
    },
    {
      name: "AI Dashboard",
      url: "/aimodule",
      type: "internal",
    },
    {
      name: "Learning Center",
      url: "https://Learningcenter.ailessonplan.com",
      type: "external",
    },
    {
      name: "Blog",
      url: "https://Blog.ailessonplan.com",
      type: "external",
    },
    {
      name: "Support",
      url: "/contact",
      type: "internal",
    },
    {
      name: "Member Rewards",
      url: "https://memberrewards.ailessonplan.com",
      type: "external",
    },
    isMobile && {
      name: "Upgrade",
      url: "/pricing",
      type: "internal",
    },
  ];
  const settings = ["Account", "Logout"];

  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  async function handleLogout() {
    try {
      const response = await fetch("https://ailessonplan.com/core/api/logout", {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      });

      const responseData = await response.json();

      if (responseData.message === "Logged out") {
        // Remove the token from the localStorage
        localStorage.removeItem("token");
        // Navigate to the /signin page
        navigate("/signing");
      } else if (responseData.message === "Unauthenticated.") {
        // Handle the failure scenario
        console.error("Failed to log out: Unauthenticated.");
      } else {
        // Handle any other unexpected errors
        console.error("Failed to log out: Unexpected error.");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  }
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box display="flex" alignItems="center" height={90} bgcolor="#29ADB2">
      <Container maxWidth="xl">
        {!isMobile ? (
          <Box
            component="img"
            src="/logo192.png"
            alt=""
            position="absolute"
            mt={-10}
          />
        ) : (
          <Box
            component="img"
            src="/favicon.ico"
            alt=""
            position="absolute"
            top={5}
            width={80}
          />
        )}
        {/* Desktop app */}
        <Stack
          direction="row"
          justifyContent="space-between"
          ml={24}
          display={{
            xl: "flex",
            lg: "flex",
            md: "flex",
            sm: "none",
            xs: "none",
          }}
        >
          <Stack direction="row" spacing={{ md: 0, lg: 2 }}>
            {taglist.map((item, index) => (
              <Button
                key={index}
                onClick={() => {
                  if (item.type === "internal") {
                    navigate(item.url);
                  } else if (item.type === "external") {
                    window.location.href = item.url;
                  }
                }}
                sx={{ color: "white", textTransform: "none" }}
              >
                {item.name}
              </Button>
            ))}
          </Stack>
          <Box mr={5} display="flex" gap={3}>
            <Button
              variant="outlined"
              onClick={() => navigate("/pricing")}
              sx={{ color: "white", border: "1px solid white" }}
            >
              Upgrade
            </Button>
            <Box>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Box
                    component="img"
                    src="/assets/images/profile/Man.png"
                    alt=""
                    width={50}
                    height={50}
                  />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting) => (
                  <MenuItem key={setting} onClick={handleCloseUserMenu}>
                    {setting === "Logout" ? (
                      <ListItemIcon>
                        <Logout fontSize="small" />
                      </ListItemIcon>
                    ) : (
                      <></>
                    )}
                    {setting === "Account" ? (
                      <ListItemIcon>
                        <Avatar sx={{ width: 24, height: 24 }} />
                      </ListItemIcon>
                    ) : (
                      <></>
                    )}
                    <Typography
                      textAlign="center"
                      onClick={function () {
                        console.log("Yes clicked: ", setting);
                        if (setting === "Logout") {
                          handleLogout();
                        } else if (setting === "Account") {
                          navigate("/account");
                        }
                      }}
                    >
                      {setting}
                    </Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Box>
        </Stack>

        {/* Mobile app */}
        <Stack
          direction="row"
          justifyContent="space-between"
          ml={10}
          display={{
            xl: "none",
            lg: "none",
            md: "none",
            sm: "flex",
            xs: "flex",
          }}
        >
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            sx={{ color: "white" }}
            size="large"
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            {taglist.map((item, index) => (
              <MenuItem
                key={index}
                onClick={() => {
                  navigate(item.url);
                  handleClose();
                }}
                sx={{ color: "black", textTransform: "none" }}
              >
                {item.name}
              </MenuItem>
            ))}
          </Menu>
          <Box mr={0} display="flex" gap={3}>
            <Box>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Box
                    component="img"
                    src="/assets/images/profile/Man.png"
                    alt=""
                    width={50}
                    height={50}
                  />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting) => (
                  <MenuItem key={setting} onClick={handleCloseUserMenu}>
                    {setting === "Logout" ? (
                      <ListItemIcon>
                        <Logout fontSize="small" />
                      </ListItemIcon>
                    ) : (
                      <></>
                    )}
                    {setting === "Account" ? (
                      <ListItemIcon>
                        <Avatar sx={{ width: 24, height: 24 }} />
                      </ListItemIcon>
                    ) : (
                      <></>
                    )}
                    <Typography
                      textAlign="center"
                      onClick={function () {
                        console.log("Yes clicked: ", setting);
                        if (setting === "Logout") {
                          handleLogout();
                        } else if (setting === "Account") {
                          navigate("/account");
                        }
                      }}
                    >
                      {setting}
                    </Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Box>
        </Stack>
      </Container>
    </Box>
  );
};

export default NewHeader;
