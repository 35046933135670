import {
  Box,
  Card,
  Container,
  Grid,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import SearchIcon from "@mui/icons-material/Search";
import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";
import AutoAwesomeOutlinedIcon from "@mui/icons-material/AutoAwesomeOutlined";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import QuestionMarkOutlinedIcon from "@mui/icons-material/QuestionMarkOutlined";
import SupportAgentOutlinedIcon from "@mui/icons-material/SupportAgentOutlined";
import QuestionAnswerOutlinedIcon from "@mui/icons-material/QuestionAnswerOutlined";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";

import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { SELlessonGen } from "../../../store/index";

const filterList = [
  {
    name: "All",
    icon: <AutoAwesomeOutlinedIcon />,
  },
  {
    name: "Planning",
    icon: <CalendarMonthOutlinedIcon />,
  },
  {
    name: "Content",
    icon: <CalendarMonthOutlinedIcon />,
  },
  {
    name: "Question",
    icon: <QuestionMarkOutlinedIcon />,
  },
  {
    name: "Student Support",
    icon: <SupportAgentOutlinedIcon />,
  },
  {
    name: "Communication",
    icon: <QuestionAnswerOutlinedIcon />,
  },
];

const Aimodule = () => {
  const token = localStorage.getItem("token");
  const [lessonGenData, setLessonGenData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [selectedFilters, setSelectedFilters] = useState([]);

  const isMobile = useMediaQuery("(max-width:600px)");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    fetchAllData();
  }, []);

  const fetchAllData = async () => {
    const response = await fetch("https://ailessonplan.com/core/api/ai-modulars", {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    });
    const data = await response.json();
    setLessonGenData(data);
  };

  const uniqueCategories = [...new Set(lessonGenData.map((item) => item.category))];

  const handleFilterClick = (category) => {
    if (selectedFilters.includes(category)) {
      setSelectedFilters(selectedFilters.filter((filter) => filter !== category));
    } else {
      setSelectedFilters([...selectedFilters, category]);
    }
  };

  const filteredData = lessonGenData.filter((el) => {
    const isInSelectedFilters = selectedFilters.length === 0 || selectedFilters.includes(el.category);
    const containsSearchText =
        searchText === "" ||
        el.title.toLowerCase().includes(searchText) ||
        el.description.toLowerCase().includes(searchText);
    return isInSelectedFilters && containsSearchText;
  });

  return (
      <Container maxWidth="xl">
        <Box bgcolor="#F4F7FF" minHeight="calc(100vh - 90px)" p={4}>
          <Card
              sx={{
                borderRadius: 1.3,
                boxShadow: "0px 0px 6px 0px rgba(0, 0, 0, 0.06)",
                px: 2,
                py: 3,
              }}
          >
            <Stack spacing={2}>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Box display={{ md: "flex", sm: "flex", xs: "block" }} width="80%" gap={3}>
                  <TextField
                      onChange={(e) => setSearchText(e.target.value.toLowerCase())}
                      placeholder="Search"
                      InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                        ),
                        style: {
                          borderRadius: "10px",
                        },
                      }}
                      sx={{ width: isMobile === true ? "100%" : "40%" }}
                      variant="outlined"
                  />
                </Box>
                {uniqueCategories.length > 0 && (
                    <Stack direction="row" display="flex" alignItems="center" spacing={2}>
                      {isMobile && <TuneOutlinedIcon />}
                      {!isMobile &&
                          uniqueCategories.map((category, index) => (
                              <TextField
                                  key={index}
                                  value={category}
                                  size="small"
                                  onClick={() => handleFilterClick(category)}
                                  InputProps={{
                                    readOnly: true, // Make the TextField uneditable
                                    startAdornment: (
                                        <InputAdornment position="start">
                                          {/* You can set icons for categories here */}
                                          {getCategoryIcon(category)}
                                        </InputAdornment>
                                    ),
                                    style: {
                                      borderRadius: "30px",
                                      backgroundColor: selectedFilters.includes(category) ? "#3D30A2" : "transparent",
                                      color: selectedFilters.includes(category) ? "white" : "inherit",
                                      cursor: "pointer", // Change cursor style to pointer
                                    },
                                  }}
                                  variant="outlined"
                              />
                          ))}
                    </Stack>

                )}
              </Box>
            </Stack>
          </Card>

          <Grid container spacing={2} mt={2}>
            {filteredData.map((item, index) => (
                <Grid item key={index} md={3} xs={12}>
                  <Card
                      onClick={() => {
                        dispatch(SELlessonGen(item));
                        navigate(`/aimodule/lessonGen`);
                      }}
                      sx={{
                        borderRadius: 1.3,
                        boxShadow: "0px 0px 6px 0px rgba(0, 0, 0, 0.06)",
                        p: 2,
                        cursor: "pointer",
                        height: "100%",
                      }}
                  >
                    <Box display="flex" justifyContent="space-between">
                      <Box
                          component="img"
                          src={item.icon === null ? "/assets/images/studying.png" : item.icon}
                          alt=""
                          width={30}
                          height={30}
                      />
                      <FavoriteBorderOutlinedIcon />
                    </Box>
                    <Typography fontSize={16} fontWeight={700} sx={{ color: "#3D30A2" }} mt={2}>
                      {item.title}
                    </Typography>
                    <Typography fontSize={16} fontWeight={400} mt={2}>
                      {item.description}
                    </Typography>
                  </Card>
                </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
  );
};

const getCategoryIcon = (category) => {
  switch (category) {
    case "lesson":
      return <AutoAwesomeOutlinedIcon />;
    case "Question":
      return <QuestionMarkOutlinedIcon />;
    case "Communication":
      return <QuestionAnswerOutlinedIcon />;
    default:
      return <AutoAwesomeOutlinedIcon />;
  }
};

export default Aimodule;
