import {Helmet} from "react-helmet";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import PricingArrow from "../Home/Helper/arrow/PricingArrow";
import * as React from "react";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

export function PricingTable2() {
	const [already_used_trial, set_already_used_trial] = useState(false);
	const [start_free_trial, set_start_free_trial] = useState(false);
	const [token, setToken] = useState(localStorage.getItem("token"));
	const [annuel_or_month, set_annuel_or_month] = useState(0);
	const [stripe, setStripe] = useState(null);
	const [isProcessing, setIsProcessing] = useState(false);
	const navigator = useNavigate();


	useEffect(() => {
		const script = document.createElement('script');
		script.src = 'https://js.stripe.com/v3/';
		script.addEventListener('load', () => {
			const stripePromise = window.Stripe('pk_live_51MsmfyKcZciro7Xgn9VGKAa4cBEwQVxL1ZJEJtnZTlsU7ikQ0fTJRFmjFstfTYmW6pWE9d7EfSgyYEAux6Pa7b2100ozVqyPNW');
			setStripe(stripePromise);
		});
		document.body.appendChild(script);
	}, []);

	function StartActiveTrial() {
		fetch('https://ailessonplan.com/core/api/active_trial', {
			method: 'GET',
			headers: {
				'Accept': 'application/json',
				'Authorization': 'Bearer ' + token
			}
		})
			.then(response => response.json())
			.then(data => parse_active_free_trial(data))
			.catch(error => console.error(error))
	}

	function parse_active_free_trial(data) {
		//console.log('active trial: ',data);
		if (data.hasOwnProperty('message')) {
			//set_start_free_trial(true)
			set_already_used_trial(true);
			return;
		}
		set_start_free_trial(false);
	}

	const handleCheckout = async (type, plan) => {
		if (isProcessing)
			return;
		if (stripe == null) {
			console.log("Stripe is null")
			return;
		}
		setIsProcessing(true);
		const response = await fetch('https://ailessonplan.com/core/api/create-checkout-session', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'Authorization': 'Bearer ' + token,
				'Accept': 'application/json',
			},
			body: new URLSearchParams({
				'type' : type,
				'plan' : plan,
				'user_id': "-1"
			}).toString(),
			//redirect: 'follow'
		});

		const j = await response.json();
		const { sessionId } = j;
		//console.log("reply: ", sessionId);
		setIsProcessing(false);

		// Redirect to the Stripe checkout page with the session ID
		stripe.redirectToCheckout({ sessionId });
	}

	return (
		<>
			<Helmet>
				<script src="https://js.stripe.com/v3/"></script>
				<script async src="https://www.googletagmanager.com/gtag/js?id=G-M8647N6J28"></script>
				<script>
					{`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-M8647N6J28');
          `}
				</script>
			</Helmet>
			{/*Already used*/}
			<Dialog
				open={already_used_trial}
				onClose={() => {set_already_used_trial(false)}}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					{"Already used"}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						You already on a Free trial
					</DialogContentText>
				</DialogContent>
			</Dialog>


			<div>
				<section className="px-6 xl:px-0" id="pricing">
					<div className="mt-10 mx-auto container">
						<div className="flex flex-col lg:items-center justify-center w-full">
							<h1 className="font-semibold text-gray-800 text-3xl md:text-4xl">Ready To Cut Hours Off Your Work Week?</h1>
							<p className="mt-2.5 lg:w-1/2 lg:text-center text-2xl">For a limited time, you can lock in special launch pricing for the Life of your account</p>
						</div>
						<div className="flex items-center justify-center w-full">
							<div className="pt-14">
								<div className="container mx-auto">
									<div className="xl:w-4/5 w-11/12 mx-auto mb-28">
										<div className="flex justify-center items-center" role="button">
											<p className="mr-8 text-lg text-gray-600 font-bold">Bill Monthly</p>
											<div className="cursor-pointer w-12 h-6 rounded-full relative shadow-sm">
												<input onClick={() => (set_annuel_or_month(annuel_or_month === 0 ? 1 : 0))} defaultChecked type="checkbox" name="toggle" id="toggle2" className="focus:outline-none checkbox w-4 h-4 rounded-full bg-indigo-700 transition duration-150 ease-in-out absolute m-1 shadow-sm appearance-none cursor-pointer" />
												<label htmlFor="toggle2" className="toggle-label block w-12 h-6 overflow-hidden rounded-full border border-indigo-700 cursor-pointer" />
											</div>
											<p className="ml-8 text-lg font-normal text-gray-800">Bill Anually Annual (Save up to 70%)</p>
											<PricingArrow/>
										</div>
									</div>
									{
										annuel_or_month === 0 ?
											(
												/*yearly payment is here*/
												<>
													<div className="flex flex-wrap mb-12 justify-between sm:justify-center -mx-6">
														<div className="w-full xl:w-1/3 lg:w-1/3 md:w-1/2 sm:w-1/2 mb-4 px-6">
															<div className="py-5 px-4 bg-white border border-gray-200shadow rounded-lg text-left">
																<h4 className="text-2xl text-indigo-700 font-semibold pb-8">Free Trial</h4>
																<ul className="flex flex-col mb-6">
																	<li className="flex items-center mb-2.5">
																		<img src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png" className="mr-4" alt="check-mark" />
																		<p className="text-gray-800 text-base font-normal">5 Custom Class Profile</p>
																	</li>
																	<li className="flex items-center mb-2.5">
																		<img src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png" className="mr-4" alt="check-mark" />
																		<p className="text-gray-800 text-base font-normal">5 Lesson plan one-time for one week</p>
																	</li>
																	<li className="flex items-center mb-2.5">
																		<img src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png" className="mr-4" alt="check-mark" />
																		<p className="text-gray-800 text-base font-normal">25 Additional Resources Included (HomeWork, WorkSheets, Rubrics, Assessments)</p>
																	</li>
																	{/*<li className="flex items-center mb-2.5">
                                                                        <img src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png" className="opacity-0 mr-4" alt="check-mark" />
                                                                        <p className="text-gray-400 text-base font-normal">Partnership + Discounts</p>
                                                                    </li>
                                                                    <li className="flex items-center mb-2.5">
                                                                        <img src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png" className="opacity-0 mr-4" alt="check-mark" />
                                                                        <p className="text-gray-400 text-base font-normal">Direct doctor phone number</p>
                                                                    </li>
                                                                    <li className="flex items-center mb-2.5">
                                                                        <img src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png" className="opacity-0 mr-4" alt="check-mark" />
                                                                        <p className="text-gray-400 text-base font-normal">Specialists appoinments</p>
                                                                    </li>*/}
																</ul>
																<p className="text-base text-indigo-700 relative pl-3">
																	<span className="font-light text-lg">$</span>
																	<span className="text-2xl font-semibold">0</span>
																	<span className="text-gray-600 font-light text-lg">/month</span>
																</p>
																<button onClick={() => {navigator('/signup')}} className="mt-5 w-full bg-gray-200 hover:bg-gray-300 focus:outline-none transition duration-150 ease-in-out rounded text-indigo-700 px-8 py-3 text-base font-semibold py-3">Start Free trial</button>
															</div>
														</div>
														<div className="w-full xl:w-1/3 lg:w-1/3 md:w-1/2 sm:w-1/2 mb-4 px-6">
															<div className="py-5 px-4 bg-indigo-700 border border-gray-200 shadow rounded-lg text-left">
																<h4 className="text-2xl text-white font-semibold pb-8">Basic</h4>
																<ul className="flex flex-col mb-6">
																	<li className="flex items-center mb-2.5">
																		<h5 className="text-2xl text-white font-semibold pb-8">SAVE 68% (Normally $708/yr) Paid Yearly for average $19/MO</h5>
																	</li>
																	<li className="flex items-center mb-2.5">
																		<img src="https://cdn.tuk.dev/assets/templates/weCare/checkMarkWhite.png" className="mr-4" alt="check-mark" />
																		<p className="text-white text-base font-normal">5 Custom Class Profile</p>
																	</li>
																	<li className="flex items-center mb-2.5">
																		<img src="https://cdn.tuk.dev/assets/templates/weCare/checkMarkWhite.png" className="mr-4" alt="check-mark" />
																		<p className="text-white text-base font-normal">25 Lesson Plans Per Month</p>
																	</li>
																	<li className="flex items-center mb-2.5">
																		<img src="https://cdn.tuk.dev/assets/templates/weCare/checkMarkWhite.png" className="mr-4" alt="check-mark" />
																		<p className="text-white text-base font-normal">25 Additional Resources Included (HomeWork, WorkSheets, Rubrics, Assessments, ..etc)</p>
																	</li>
																	<li className="flex items-center mb-2.5">
																		<img src="https://cdn.tuk.dev/assets/templates/weCare/checkMarkWhite.png" className="mr-4" alt="check-mark" />
																		<p className="text-white text-base font-normal">Access to all lesson plan formats</p>
																	</li>
																	<li className="flex items-center mb-2.5">
																		<img src="https://cdn.tuk.dev/assets/templates/weCare/checkMarkWhite.png" className="mr-4" alt="check-mark" />
																		<p className="text-white text-base font-normal">All Features included</p>
																	</li>
																	<li className="flex items-center mb-2.5">
																		<img src="https://cdn.tuk.dev/assets/templates/weCare/checkMarkWhite.png" className="mr-4" alt="check-mark" />
																		<p className="text-white text-base font-normal">No Software install needed</p>
																	</li>
																	<li className="flex items-center mb-2.5">
																		<img src="https://cdn.tuk.dev/assets/templates/weCare/checkMarkWhite.png" className="mr-4" alt="check-mark" />
																		<p className="text-white text-base font-normal">Cloud Based generative AI</p>
																	</li>
																</ul>
																<p className="text-base text-white relative pl-3">
																	<span className="font-light text-lg">$</span>
																	<span className="text-2xl font-semibold">229</span>
																	<span className="font-light text-lg">/year</span>
																</p>
																<button onClick={() => {navigator('/signup')}} className="mt-5 w-full text-indigo-700 focus:outline-none transition duration-150 ease-in-out rounded bg-white hover:bg-gray-100 px-8 py-3 text-base font-semibold py-3">Start Now</button>
															</div>
														</div>
														<div className="w-full xl:w-1/3 lg:w-1/3 md:w-1/2 sm:w-1/2 mb-4 px-6">
															<div className="py-5 px-4 bg-white border border-gray-200shadow rounded-lg text-left">
																<h4 className="text-2xl text-indigo-700 font-semibold pb-8">Enterprise</h4>
																<ul className="flex flex-col mb-6">
																	<li className="flex items-center mb-2.5">
																		<h5 className="text-2xl text-indigo-700 font-semibold pb-8">Save 70% Normally $1999 after</h5>
																	</li>
																	<li className="flex items-center mb-2.5">
																		<img src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png" className="mr-4" alt="check-mark" />
																		<p className="text-gray-800 text-base font-normal">10 Custom Class Profile</p>
																	</li>
																	<li className="flex items-center mb-2.5">
																		<img src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png" className="mr-4" alt="check-mark" />
																		<p className="text-gray-800 text-base font-normal">25 Lesson Plans Per Month</p>
																	</li>
																	<li className="flex items-center mb-2.5">
																		<img src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png" className="mr-4" alt="check-mark" />
																		<p className="text-gray-800 text-base font-normal">25 Additional Resources Included (HomeWork, WorkSheets, Rubrics, Assessments, ..etc)</p>
																	</li>
																	<li className="flex items-center mb-2.5">
																		<img src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png" className="mr-4" alt="check-mark" />
																		<p className="text-gray-800 text-base font-normal">Access to all lesson plan formats</p>
																	</li>
																	<li className="flex items-center mb-2.5">
																		<img src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png" className="mr-4" alt="check-mark" />
																		<p className="text-gray-800 text-base font-normal">All Features included</p>
																	</li>
																	<li className="flex items-center mb-2.5">
																		<img src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png" className="mr-4" alt="check-mark" />
																		<p className="text-gray-800 text-base font-normal">No Software install needed</p>
																	</li>
																	<li className="flex items-center mb-2.5">
																		<img src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png" className="mr-4" alt="check-mark" />
																		<p className="text-gray-800 text-base font-normal">Cloud based generative AI</p>
																	</li>
																	<li className="flex items-center mb-2.5">
																		<img src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png" className="mr-4" alt="check-mark" />
																		<p className="text-gray-800 text-base font-normal">24/7 Support</p>
																	</li>
																</ul>
																<p className="text-base text-indigo-700 relative pl-3">
																	<span className="font-light text-lg">$</span>
																	<span className="text-2xl font-semibold">599</span>
																	<span className="font-light text-lg"> one-time</span>
																</p>
																<button onClick={() => {navigator('/signup')}} className="mt-5 w-full bg-gray-200 hover:bg-gray-300 focus:outline-none transition duration-150 ease-in-out rounded text-indigo-700 px-8 py-3 text-base font-semibold py-3">Start Now</button>
															</div>
														</div>
													</div>
												</>
											)
											:
											(
												<>
													<div className="flex flex-wrap mb-12 justify-between sm:justify-center -mx-6">
														<div className="w-full xl:w-1/3 lg:w-1/3 md:w-1/2 sm:w-1/2 mb-4 px-6">
															<div className="py-5 px-4 bg-white border border-gray-200shadow rounded-lg text-left">
																<h4 className="text-2xl text-indigo-700 font-semibold pb-8">Free Trial</h4>
																<ul className="flex flex-col mb-6">
																	<li className="flex items-center mb-2.5">
																		<img src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png" className="mr-4" alt="check-mark" />
																		<p className="text-gray-800 text-base font-normal">5 Custom Class Profile</p>
																	</li>
																	<li className="flex items-center mb-2.5">
																		<img src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png" className="mr-4" alt="check-mark" />
																		<p className="text-gray-800 text-base font-normal">5 Lesson plan one-time for one week</p>
																	</li>
																	<li className="flex items-center mb-2.5">
																		<img src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png" className="mr-4" alt="check-mark" />
																		<p className="text-gray-800 text-base font-normal">25 Additional Resources Included (HomeWork, WorkSheets, Rubrics, Assessments)</p>
																	</li>
																	{/*<li className="flex items-center mb-2.5">
                                                                        <img src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png" className="opacity-0 mr-4" alt="check-mark" />
                                                                        <p className="text-gray-400 text-base font-normal">Partnership + Discounts</p>
                                                                    </li>
                                                                    <li className="flex items-center mb-2.5">
                                                                        <img src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png" className="opacity-0 mr-4" alt="check-mark" />
                                                                        <p className="text-gray-400 text-base font-normal">Direct doctor phone number</p>
                                                                    </li>
                                                                    <li className="flex items-center mb-2.5">
                                                                        <img src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png" className="opacity-0 mr-4" alt="check-mark" />
                                                                        <p className="text-gray-400 text-base font-normal">Specialists appoinments</p>
                                                                    </li>*/}
																</ul>
																<p className="text-base text-indigo-700 relative pl-3">
																	<span className="font-light text-lg">$</span>
																	<span className="text-2xl font-semibold">0</span>
																	<span className="text-gray-600 font-light text-lg">/month</span>
																</p>
																<button onClick={StartActiveTrial} className="mt-5 w-full bg-gray-200 hover:bg-gray-300 focus:outline-none transition duration-150 ease-in-out rounded text-indigo-700 px-8 py-3 text-base font-semibold py-3">Choose</button>
															</div>
														</div>
														<div className="w-full xl:w-1/3 lg:w-1/3 md:w-1/2 sm:w-1/2 mb-4 px-6">
															<div className="py-5 px-4 bg-indigo-700 border border-gray-200 shadow rounded-lg text-left">
																<h4 className="text-2xl text-white font-semibold pb-8">Basic</h4>
																<ul className="flex flex-col mb-6">
																	<li className="flex items-center mb-2.5">
																		<img src="https://cdn.tuk.dev/assets/templates/weCare/checkMarkWhite.png" className="mr-4" alt="check-mark" />
																		<p className="text-white text-base font-normal">5 Custom Class Profile</p>
																	</li>
																	<li className="flex items-center mb-2.5">
																		<img src="https://cdn.tuk.dev/assets/templates/weCare/checkMarkWhite.png" className="mr-4" alt="check-mark" />
																		<p className="text-white text-base font-normal">25 Lesson Plans Per Month</p>
																	</li>
																	<li className="flex items-center mb-2.5">
																		<img src="https://cdn.tuk.dev/assets/templates/weCare/checkMarkWhite.png" className="mr-4" alt="check-mark" />
																		<p className="text-white text-base font-normal">25 Additional Resources Included (HomeWork, WorkSheets, Rubrics, Assessments, ..etc)</p>
																	</li>
																	<li className="flex items-center mb-2.5">
																		<img src="https://cdn.tuk.dev/assets/templates/weCare/checkMarkWhite.png" className="mr-4" alt="check-mark" />
																		<p className="text-white text-base font-normal">Access to all lesson plan formats</p>
																	</li>
																	<li className="flex items-center mb-2.5">
																		<img src="https://cdn.tuk.dev/assets/templates/weCare/checkMarkWhite.png" className="mr-4" alt="check-mark" />
																		<p className="text-white text-base font-normal">All Features included</p>
																	</li>
																	<li className="flex items-center mb-2.5">
																		<img src="https://cdn.tuk.dev/assets/templates/weCare/checkMarkWhite.png" className="mr-4" alt="check-mark" />
																		<p className="text-white text-base font-normal">No Software install needed</p>
																	</li>
																	<li className="flex items-center mb-2.5">
																		<img src="https://cdn.tuk.dev/assets/templates/weCare/checkMarkWhite.png" className="mr-4" alt="check-mark" />
																		<p className="text-white text-base font-normal">Cloud Based generative AI</p>
																	</li>
																</ul>
																<p className="text-base text-white relative pl-3">
																	<span className="font-light text-lg">$</span>
																	<span className="text-2xl font-semibold">29</span>
																	<span className="font-light text-lg">/month</span>
																</p>
																<button onClick={() => {navigator('/signup')}} className="mt-5 w-full text-indigo-700 focus:outline-none transition duration-150 ease-in-out rounded bg-white hover:bg-gray-100 px-8 py-3 text-base font-semibold py-3">Start Now</button>
															</div>
														</div>
														<div className="w-full xl:w-1/3 lg:w-1/3 md:w-1/2 sm:w-1/2 mb-4 px-6">
															<div className="py-5 px-4 bg-white border border-gray-200shadow rounded-lg text-left">
																<h4 className="text-2xl text-indigo-700 font-semibold pb-8">Enterprise</h4>
																<ul className="flex flex-col mb-6">
																	<li className="flex items-center mb-2.5">
																		<img src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png" className="mr-4" alt="check-mark" />
																		<p className="text-gray-800 text-base font-normal">10 Custom Class Profile</p>
																	</li>
																	<li className="flex items-center mb-2.5">
																		<img src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png" className="mr-4" alt="check-mark" />
																		<p className="text-gray-800 text-base font-normal">25 Lesson Plans Per Month</p>
																	</li>
																	<li className="flex items-center mb-2.5">
																		<img src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png" className="mr-4" alt="check-mark" />
																		<p className="text-gray-800 text-base font-normal">25 Additional Resources Included (HomeWork, WorkSheets, Rubrics, Assessments, ..etc)</p>
																	</li>
																	<li className="flex items-center mb-2.5">
																		<img src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png" className="mr-4" alt="check-mark" />
																		<p className="text-gray-800 text-base font-normal">Access to all lesson plan formats</p>
																	</li>
																	<li className="flex items-center mb-2.5">
																		<img src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png" className="mr-4" alt="check-mark" />
																		<p className="text-gray-800 text-base font-normal">All Features included</p>
																	</li>
																	<li className="flex items-center mb-2.5">
																		<img src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png" className="mr-4" alt="check-mark" />
																		<p className="text-gray-800 text-base font-normal">No Software install needed</p>
																	</li>
																	<li className="flex items-center mb-2.5">
																		<img src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png" className="mr-4" alt="check-mark" />
																		<p className="text-gray-800 text-base font-normal">Cloud based generative AI</p>
																	</li>
																	<li className="flex items-center mb-2.5">
																		<img src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png" className="mr-4" alt="check-mark" />
																		<p className="text-gray-800 text-base font-normal">24/7 Support</p>
																	</li>
																</ul>
																<p className="text-base text-indigo-700 relative pl-3">
																	<span className="font-light text-lg">$</span>
																	<span className="text-2xl font-semibold">249</span>
																	<span className="font-light text-lg">/MO (3 times only)</span>
																</p>
																<button onClick={() => {navigator('/signup')}} className="mt-5 w-full bg-gray-200 hover:bg-gray-300 focus:outline-none transition duration-150 ease-in-out rounded text-indigo-700 px-8 py-3 text-base font-semibold py-3">Start Now</button>
															</div>
														</div>
													</div>
												</>
											)
									}
								</div>
								<style
									dangerouslySetInnerHTML={{
										__html: "",
									}}
								/>
							</div>
						</div>
					</div>
				</section>

				<style>
					{` 
            .checkbox:checked {
                right: 0;
                background-color: #4338ca;
            }
            `}
				</style>
			</div>
		</>
	)
}