import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import { Checkbox, FormControlLabel } from "@mui/material";
import DialogContentText from "@mui/material/DialogContentText";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
function Wizard() {
  const [open, setOpen] = useState(false);
  const [show_wizard, set_show_wizard] = useState(
    localStorage.getItem("show_wizard")
  );

  const handleClose = () => {
    setOpen(false);
    set_show_wizard("false");
    //console.log("yes closed press");
  };

  useEffect(() => {
    if (show_wizard === null) {
      setOpen(true);
    } else if (show_wizard === "true") {
      setOpen(true);
    } else {
      setOpen(false);
    }
    //setOpen(show_wizard);
  }, [show_wizard]);

  function onDontShowAgain(event) {
    //console.log('valu of the radio button ', event.target.checked);
    if (event.target.checked) {
      localStorage.setItem("show_wizard", "false");
    } else {
      localStorage.setItem("show_wizard", "true");
    }
  }

  return (
    <>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="md"
        fullWidth={true}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Welcome To AI-Lesson Plan (Quick Tutorial)
        </BootstrapDialogTitle>
        <DialogContent dividers>
          {/* <DialogContentText> */}
          <div
            style={{
              position: "relative",
              paddingBottom: "56.25%",
              height: 0,
              overflow: "hidden",
            }}
          >
            <iframe
              width="100%"
              height="100%"
              src="https://komododecks.com/embed/recordings/J6uMo2xqu143hPXwpdmX?onlyRecording=1"
              allowFullScreen
              style={{ position: "absolute", top: 0, left: 0, border: "0" }}
            />
          </div>
          {/* </DialogContentText> */}
        </DialogContent>
        <DialogActions>
          <FormControlLabel
            control={<Checkbox onChange={onDontShowAgain} />}
            label="Dont show again"
          />
          {/*<Button onClick={handleClose}>
                      Close
                  </Button>*/}
        </DialogActions>
      </BootstrapDialog>
    </>
  );
}

export default Wizard;
