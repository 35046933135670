import * as React from 'react';
import {useEffect, useState} from 'react';
import {Box, CircularProgress, Container, Typography} from '@mui/material';
import {useParams} from "react-router-dom";
import {Helmet} from "react-helmet";
import parse from 'html-react-parser';
function htmlToReactComponents(htmlString) {
    const parser = new DOMParser();
    const htmlDoc = parser.parseFromString(htmlString, 'text/html');

    const componentMap = {
            p: ({children}) => <p>{children}</p>,
            h1: ({children}) => <h1>{children}</h1>,
            h2: ({children}) => <h2>{children}</h2>,
            h3: ({children}) => <h3>{children}</h3>,
            h4: ({children}) => <h4>{children}</h4>,
            h5: ({children}) => <h5>{children}</h5>,
            h6: ({children}) => <h6>{children}</h6>,
            div: ({children}) => <div>{children}</div>,
            span: ({children}) => <span>{children}</span>,
            a: ({href, children}) => <a href={href}>{children}</a>,
            img: ({src, alt}) => <img src={src} alt={alt}/>,
            ul: ({children}) => <ul>{children}</ul>,
            ol: ({children}) => <ol>{children}</ol>,
            li: ({children}) => <li>{children}</li>,
            table: ({children}) => <table>{children}</table>,
            thead: ({children}) => <thead>{children}</thead>,
            tbody: ({children}) => <tbody>{children}</tbody>,
            tr: ({children}) => <tr>{children}</tr>,
            th: ({children}) => <th>{children}</th>,
            td: ({children}) => <td>{children}</td>,
            blockquote: ({children}) => <blockquote>{children}</blockquote>,
            code: ({children}) => <code>{children}</code>,
            pre: ({children}) => <pre>{children}</pre>,
            hr: () => <hr/>,
            br: () => <br/>,
            strong: ({children}) => <strong>{children}</strong>,
            em: ({children}) => <em>{children}</em>,
            u: ({children}) => <u>{children}</u>,
            del: ({children}) => <del>{children}</del>,
            ins: ({children}) => <ins>{children}</ins>,
            sup: ({children}) => <sup>{children}</sup>,
            sub: ({children}) => <sub>{children}</sub>,
            small: ({children}) => <small>{children}</small>,
            big: ({children}) => <big>{children}</big>,
            mark: ({children}) => <mark>{children}</mark>,
            q: ({children}) => <q>{children}</q>,
            cite: ({children}) => <cite>{children}</cite>,
            dfn: ({children}) => <dfn>{children}</dfn>,
            abbr: ({children}) => <abbr>{children}</abbr>,
            address: ({children}) => <address>{children}</address>,
            b: ({children}) => <b>{children}</b>,
            i: ({children}) => <i>{children}</i>,
            s: ({children}) => <s>{children}</s>,
            kbd: ({children}) => <kbd>{children}</kbd>,
            samp: ({children}) => <samp>{children}</samp>,
            var: ({children}) => <var>{children}</var>,
            summary: ({children}) => <summary>{children}</summary>,
            details: ({children}) => <details>{children}</details>
        }

    const convertHtmlToComponents = (node) => {
        if (node.nodeType === Node.TEXT_NODE) {
            return node.textContent;
        }

        if (node.nodeType !== Node.ELEMENT_NODE) {
            return null;
        }

        const TagName = node.tagName.toLowerCase();
        const children = Array.from(node.childNodes).map(convertHtmlToComponents);

        if (componentMap[TagName]) {
            return React.createElement(componentMap[TagName], {
                key: children.join(''),
                ...node.attributes,
                children,
            });
        }

        return children;
    };

    return Array.from(htmlDoc.body.childNodes).map(convertHtmlToComponents);
}
function BlogArticle() {
    let { id} = useParams();
    const [isLoading, set_Loading] = useState(true);
    const [token, setToken] = useState(localStorage.getItem("token"));
    const [blogPost, setBlogPost] = useState(null);

    useEffect(() => {
        set_Loading(true);
        const fetchData = async () => {
            const response = await fetch('https://ailessonplan.com/core/api/blog_post?id=' + id, {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            });

            const data = await response.json();
            setBlogPost(data);
            set_Loading(false);
        };

        fetchData();
    }, []);

    return(
        isLoading ?
            (
                <>
                    <Box sx={{display: 'flex', justifyContent: 'center', marginTop: 10}}>
                        <CircularProgress/>
                    </Box>
                </>
            )
            :
            (
                <>
                    <Helmet>
                        <script async src="https://www.googletagmanager.com/gtag/js?id=G-M8647N6J28"></script>
                        <script>
                            {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-M8647N6J28');
          `}
                        </script>
                    </Helmet>
                    <Box sx={{ bgcolor: '#f5f5f5', height: '100vh', overflow: 'auto'}}>
                        <Container maxWidth="md">
                            {/* Image at top */}
                            {/*<img src="https://source.unsplash.com/random/1600x900" style={{ width: '100%' }} alt="Article"/>*/}
                            <img src={blogPost.logo} style={{ width: '100%' }} alt="Article"/>

                            {/* Title and date */}
                            <Typography variant="h4" sx={{ mt: 4, mb: 2 }}>
                                {blogPost.title}
                            </Typography>
                            <Typography variant="subtitle1" sx={{ mb: 4 }}>
                                Published on {blogPost.written_at} By {blogPost.author}
                            </Typography>
                            {
                                parse("<div>" + blogPost.content + "</div>")
                            }
                            {/*<div dangerouslySetInnerHTML={{ __html: '' + blogPost.content.trim() }} />*/}
                            {/* HTML content */}
                            {/*<div dangerouslySetInnerHTML={{ __html: '' + blogPost.content.trim() }} />*/}
                            {/*<div>{parse(blogPost.content)}</div>*/}
                        </Container>
                    </Box>
                </>
            )
    )
}

export default BlogArticle;