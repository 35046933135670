import React from 'react';
import './styles.css';

const PricingArrow = () => {
	return (
		<img
			className="elImage"
			src="https://statics.myclickfunnels.com/image/30807/file/bc2f3917b4334f99e56fecd9b6cc5a40.svg"
			alt="blue arrow pointing to funnel hacker plan"
			width="55"
			height=""
		/>
	);
};

export default PricingArrow;
