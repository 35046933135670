import {useEffect} from "react";
import {useNavigate} from "react-router-dom";

function ThankYou_Payment() {
    const navigator = useNavigate();
    useEffect(() => {
        const timeout = setTimeout(() => {
            navigator('/lessons');
        }, 5000);

        return () => clearTimeout(timeout);
    }, [navigator]);

    return (
        <html lang="en">
        <head>
            <meta charSet="utf-8" />
            <meta httpEquiv="X-UA-Compatible" content="IE=edge,chrome=1" />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <title></title>
            <link href='https://fonts.googleapis.com/css?family=Lato:300,400|Montserrat:700' rel='stylesheet' type='text/css' />
            <style>
                {`@import url(//cdnjs.cloudflare.com/ajax/libs/normalize/3.0.1/normalize.min.css);
            @import url(//maxcdn.bootstrapcdn.com/font-awesome/4.2.0/css/font-awesome.min.css);`}
            </style>
            <link rel="stylesheet" href="https://2-22-4-dot-lead-pages.appspot.com/static/lp918/min/default_thank_you.css" />
            <script src="https://2-22-4-dot-lead-pages.appspot.com/static/lp918/min/jquery-1.9.1.min.js"></script>
            <script src="https://2-22-4-dot-lead-pages.appspot.com/static/lp918/min/html5shiv.js"></script>
        </head>
        <body>
        <header className="site-header" id="header">
            <h1 className="site-header__title" data-lead-id="site-header-title">THANK YOU!</h1>
        </header>

        <div className="main-content">
            <i className="fa fa-check main-content__checkmark" id="checkmark"></i>
            <p className="main-content__body" data-lead-id="main-content-body">Thank you for subscribing to our AI Lesson Plan service! Your payment has been successfully processed, and we are excited to have you on board. You will be automatically redirected to your personalized dashboard where you can access your plan and start learning. We hope you enjoy your AI Lesson Plan and look forward to supporting you on your learning journey.</p>
        </div>

        <footer className="site-footer" id="footer">
            <p className="site-footer__fineprint" id="fineprint">©2023 AI-Lesson Plan All Rights Reserved</p>
        </footer>
        </body>
        </html>
    );
}

export default ThankYou_Payment;