import * as React from "react";
import { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import { Link, useNavigate } from "react-router-dom";
import AlertDialog from "./Helper/AlertDialog";
import { ListItemIcon } from "@mui/material";
import { Logout } from "@mui/icons-material";

const pages = ["Lessons", "Pricing", "Blog"];
const settings = ["Account", "Logout"];

function HomeHeader() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [token, setToken] = useState(localStorage.getItem("token"));
  const navigator = useNavigate();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  /*function handleLogout() {
        fetch('https://ailessonplan.com/core/api/logout', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(response => {
                // Handle the response from the server
                if (response.ok) {
                    // If the response status is in the 200-299 range, the request was successful
                    // You can redirect the user to the login page or update the UI accordingly
                    navigator('/');
                    localStorage.removeItem('token');
                } else {
                    // If the response status is outside the 200-299 range, the request failed
                    // You can handle the error by displaying an error message or retrying the request
                }
            })
            .catch(error => {
                // Handle any errors that occurred during the request
                console.error('Error:', error);
            });
    }*/

  async function handleLogout() {
    try {
      const response = await fetch("https://ailessonplan.com/core/api/logout", {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      });

      const responseData = await response.json();

      if (responseData.message === "Logged out") {
        // Remove the token from the localStorage
        localStorage.removeItem("token");
        // Navigate to the /signin page
        navigator("/signing");
      } else if (responseData.message === "Unauthenticated.") {
        // Handle the failure scenario
        console.error("Failed to log out: Unauthenticated.");
      } else {
        // Handle any other unexpected errors
        console.error("Failed to log out: Unexpected error.");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  }

  return (
    <>
      {/*<AlertDialog/>*/}
      <AppBar position="static" sx={{ bgcolor: "#29ADB2" }}>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            {/*<AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />*/}
            {/* <Avatar src={"/favicon.ico"} />
                        <Typography
                            variant="h6"
                            noWrap
                            component="a"
                            href="/lessons"
                            sx={{
                                mr: 2,
                                display: { xs: 'none', md: 'flex' },
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                color: 'inherit',
                                textDecoration: 'none',
                            }}
                        >
                            -Lesson Plan
                        </Typography> */}
            <Box
              component="img"
              src="/logo192.png"
              alt=""
              onClick={() => navigator("/lessons")}
              height={100}
              sx={{ cursor: "pointer" }}
            />

            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {pages.map((page) => (
                  /*<MenuItem key={page} onClick={handleCloseNavMenu}>
                                        <Typography textAlign="center">{page}</Typography>
                                    </MenuItem>*/
                  <MenuItem
                    key={page}
                    onClick={function () {
                      console.log(page);
                      if (page === "Lessons") {
                        navigator("lessons");
                      } else if (page === "Pricing") navigator("pricing");
                      else if (page === "Blog")
                        //navigator('https://blog.ailessonplan.com');
                        window.location.href = "https://blog.ailessonplan.com";
                    }}
                  >
                    <Typography textAlign="center">{page}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            <AdbIcon sx={{ display: { xs: "flex", md: "none" }, mr: 1 }} />
            <Typography
              variant="h5"
              noWrap
              component="a"
              href=""
              sx={{
                mr: 2,
                display: { xs: "flex", md: "none" },
                flexGrow: 1,
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              LOGO
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              {pages.map((page) => (
                <Button
                  key={page}
                  onClick={function () {
                    console.log(page);
                    if (page === "Lessons") {
                      navigator("lessons");
                    } else if (page === "Pricing") {
                      navigator("pricing");
                    } else if (page === "Blog")
                      //navigator('https://blog.ailessonplan.com');
                      window.location.href = "https://blog.ailessonplan.com";
                  }}
                  sx={{ my: 2, color: "white", display: "block" }}
                >
                  {page}
                </Button>
              ))}
            </Box>

            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Box
                    component="img"
                    src="/assets/images/profile/Man.png"
                    alt=""
                    width={50}
                    height={50}
                  />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting) => (
                  <MenuItem key={setting} onClick={handleCloseUserMenu}>
                    {setting === "Logout" ? (
                      <ListItemIcon>
                        <Logout fontSize="small" />
                      </ListItemIcon>
                    ) : (
                      <></>
                    )}
                    {setting === "Account" ? (
                      <ListItemIcon>
                        <Avatar sx={{ width: 24, height: 24 }} />
                      </ListItemIcon>
                    ) : (
                      <></>
                    )}
                    <Typography
                      textAlign="center"
                      onClick={function () {
                        console.log("Yes clicked: ", setting);
                        if (setting === "Logout") {
                          handleLogout();
                        } else if (setting === "Account") {
                          navigator("/account");
                        }
                      }}
                    >
                      {setting}
                    </Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
}
export default HomeHeader;
