import { createSlice } from "@reduxjs/toolkit";

export const lessonSlice = createSlice({
  name: "lesson",
  initialState: {
    item: {},
  },
  reducers: {
    SELlessonGen: (state, action) => {
      return (state = {
        item: action.payload,
      });
    },
  },
});

// Action creators are generated for each case reducer function
export const { SELlessonGen } = lessonSlice.actions;

export default lessonSlice.reducer;
