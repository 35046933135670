import { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import Box from "@mui/material/Box";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import * as React from "react";

function BlogTab() {
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [blogs, setBlogs] = useState([]);
  const [isLoading, set_Loading] = useState(true);
  const navigator = useNavigate();
  const [right_blogs, set_right_blogs] = useState([]);
  const [left_blogs, set_left_blogs] = useState([]);
  const { from_free } = useParams();

  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  useEffect(() => {
    set_Loading(true);
    const fetchData = async () => {
      const response = await fetch("https://ailessonplan.com/core/api/blogs", {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      });

      const data = await response.json();
      setBlogs(data);
      console.log("main blogs size: ", blogs.length);
      console.log(blogs);

      //set_left_blogs(evenIndexBlogs);
      //set_right_blogs(oddIndexBlogs);
      /*console.log(left_blogs.length);
            console.log(right_blogs.length);

            console.log(left_blogs);
            console.log(right_blogs);*/

      set_Loading(false);
    };

    fetchData();
  }, []);

  function on_readMore(id) {
    if (from_free === null || from_free === undefined)
      navigator("/blog_article/" + id);
    else navigator("/free/blog_article/" + id);
  }

  function get_me_right_side() {
    const evenIndexBlogs = blogs.filter((blog, index) => {
      //console.log("INDEX EVEN: ", index);
      return index % 2 === 0;
    });
    return evenIndexBlogs;
  }

  function get_me_left_side() {
    const oddIndexBlogs = blogs.filter((blog, index) => {
      //console.log("INDEX ODD: ", index);
      return index % 2 !== 0;
    });
    return oddIndexBlogs;
  }

  return isLoading ? (
    <>
      <Box sx={{ display: "flex", justifyContent: "center", marginTop: 10 }}>
        <CircularProgress />
      </Box>
    </>
  ) : (
    <>
      <>
        <Helmet>
          <script
            async
            src="https://www.googletagmanager.com/gtag/js?id=G-M8647N6J28"
          ></script>
          <script>
            {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-M8647N6J28');
          `}
          </script>
        </Helmet>
        <div id="blog" className="bg-gray-100 px-4 xl:px-0 py-12">
          <div className="mx-auto container">
            <h1 className="text-center text-3xl lg:text-5xl tracking-wider text-gray-900">
              Latest from our Blog
            </h1>
            <div className="mt-12 lg:mt-24">
              <div className="grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 gap-8">
                <div>
                  <img className="w-full" src={blogs[0].logo} alt="computer" />
                  <div className="py-4 px-8 w-full flex justify-between bg-indigo-700">
                    <p className="text-sm text-white font-semibold tracking-wide">
                      {blogs[0].author}
                    </p>
                    <p className="text-sm text-white font-semibold tracking-wide">
                      {blogs[0].written_at}
                    </p>
                  </div>
                  <div className="bg-white px-10 py-6 rounded-bl-3xl rounded-br-3xl">
                    <h1 className="text-4xl text-gray-900 font-semibold tracking-wider">
                      {blogs[0].title}
                    </h1>
                    <p className="text-gray-700 text-base lg:text-lg  lg:leading-8 tracking-wide mt-6 w-11/12">
                      {blogs[0].sub_title}
                    </p>
                    <div className="w-full mt-4 justify-end flex items-center cursor-pointer">
                      <p
                        onClick={() => {
                          on_readMore(blogs[0].slug);
                        }}
                        className="text-base tracking-wide text-indigo-500"
                      >
                        Read more
                      </p>
                      <svg
                        className="ml-3 lg:ml-6"
                        xmlns="http://www.w3.org/2000/svg"
                        width={20}
                        height={18}
                        viewBox="0 0 20 18"
                        fill="none"
                      >
                        <path
                          d="M11.7998 1L18.9998 8.53662L11.7998 16.0732"
                          stroke="#4338ca"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M1 8.53662H19"
                          stroke="#4338ca"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                    <div className="h-5 w-2" />
                  </div>
                </div>
                <div>
                  <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-8">
                    {get_me_right_side().map((blog) => (
                      <Box
                        key={blog.id}
                        onClick={() => {
                          on_readMore(blog.slug);
                        }}
                      >
                        <img className="w-full" src={blog.logo} alt="games" />
                        <div className="py-2 px-4 w-full flex justify-between bg-indigo-700">
                          <p className="text-sm text-white font-semibold tracking-wide">
                            {blog.author}
                          </p>
                          <p className="text-sm text-white font-semibold tracking-wide">
                            {blog.written_at}
                          </p>
                        </div>
                        <div className="bg-white px-3  lg:px-6 py-4 rounded-bl-3xl rounded-br-3xl">
                          <h1 className="text-lg text-gray-900 font-semibold tracking-wider">
                            {blog.title}
                          </h1>
                          <p className="text-gray-700 text-sm  lg:text-base  lg:leading-8 pr-4 tracking-wide mt-2">
                            {blog.sub_title}...
                          </p>
                        </div>
                      </Box>
                    ))}
                    {/*<div>
                                                  <img className="w-full" src="https://cdn.tuk.dev/assets/components/111220/Blg-6/blog(3).png" alt="notes" />
                                                  <div className="py-2 px-4 w-full flex justify-between bg-indigo-700">
                                                      <p className="text-sm text-white font-semibold tracking-wide">Bruce Wayne</p>
                                                      <p className="text-sm text-white font-semibold tracking-wide">13TH Oct, 2020</p>
                                                  </div>
                                                  <div className="bg-white px-3  lg:px-6 py-4 rounded-bl-3xl rounded-br-3xl">
                                                      <h1 className="text-lg text-gray-900 font-semibold tracking-wider">Transactions</h1>
                                                      <p className="text-gray-700 text-sm  lg:text-base  lg:leading-8 pr-4 tracking-wide mt-2">Find the latest events updates or create events, concerts, conferences, workshops...</p>
                                                  </div>
                                              </div>*/}
                  </div>
                  <div className="mt-10 grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-8">
                    {get_me_left_side().map((blog) => (
                      <Box
                        key={blog.id}
                        onClick={() => {
                          on_readMore(blog.slug);
                        }}
                      >
                        <img className="w-full" src={blog.logo} alt="games" />
                        <div className="py-2 px-4 w-full flex justify-between bg-indigo-700">
                          <p className="text-sm text-white font-semibold tracking-wide">
                            {blog.author}
                          </p>
                          <p className="text-sm text-white font-semibold tracking-wide">
                            {blog.written_at}
                          </p>
                        </div>
                        <div className="bg-white px-3  lg:px-6 py-4 rounded-bl-3xl rounded-br-3xl">
                          <h1 className="text-lg text-gray-900 font-semibold tracking-wider">
                            {blog.title}
                          </h1>
                          <p className="text-gray-700 text-sm  lg:text-base  lg:leading-8 pr-4 tracking-wide mt-2">
                            {blog.sub_title}...
                          </p>
                        </div>
                      </Box>
                    ))}
                    {/*<div>
                                                  <img className="w-full" src="https://cdn.tuk.dev/assets/components/111220/Blg-6/blog(4).png" alt="laptop" />
                                                  <div className="py-2 px-4 w-full flex justify-between bg-indigo-700">
                                                      <p className="text-sm text-white font-semibold tracking-wide">Bruce Wayne</p>
                                                      <p className="text-sm text-white font-semibold tracking-wide">13TH Oct, 2020</p>
                                                  </div>
                                                  <div className="bg-white px-3  lg:px-6 py-4 rounded-bl-3xl rounded-br-3xl">
                                                      <h1 className="text-lg text-gray-900 font-semibold tracking-wider">Transactions</h1>
                                                      <p className="text-gray-700 text-sm  lg:text-base  lg:leading-8 pr-4 tracking-wide mt-2">Find the latest events updates or create events, concerts, conferences, workshops...</p>
                                                  </div>
                                              </div>
                                              <div>
                                                  <img className="w-full" src="https://cdn.tuk.dev/assets/components/111220/Blg-6/blog(5).png" alt="worker" />
                                                  <div className="py-2 px-4 w-full flex justify-between bg-indigo-700">
                                                      <p className="text-sm text-white font-semibold tracking-wide">Bruce Wayne</p>
                                                      <p className="text-sm text-white font-semibold tracking-wide">13TH Oct, 2020</p>
                                                  </div>
                                                  <div className="bg-white px-3  lg:px-6 py-4 rounded-bl-3xl rounded-br-3xl">
                                                      <h1 className="text-lg text-gray-900 font-semibold tracking-wider">Transactions</h1>
                                                      <p className="text-gray-700 text-sm  lg:text-base  lg:leading-8 pr-4 tracking-wide mt-2">Find the latest events updates or create events, concerts, conferences, workshops...</p>
                                                  </div>
                                              </div>*/}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
}

export default BlogTab;
