import {
  Button,
  Grid,
  Box,
  Stack,
  InputAdornment,
  TextField,
  Container,
  Typography,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogActions,
} from "@mui/material";
import React, { useState } from "react";
import { EditorState, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { convertFromHTML } from 'draft-js';
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import LoadingComponent from "../../../components/LoadingComponents/LoadingComponent";

const SELModule = () => {
  const selData = useSelector((data) => data.lessonGen.item);
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  const [state, setState] = useState(EditorState.createEmpty());
  const [genData, setGenData] = useState({});
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");

  const onEditorStateChange = (newState) => {
    setState(newState);
  };

  function containsHTMLTags(str) {
    // Regular expression to match HTML tags
    const htmlTagPattern = /<[^>]*>/;

    // Test the string against the regular expression
    return htmlTagPattern.test(str);
  }


  const [htmlContent, setHtmlContent] = useState('');
  const [isTable, setIsTable] = useState(false);

  const handleGenerate = async () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("title", title);
    urlencoded.append("params", JSON.stringify(genData));
    urlencoded.append("module", selData.id);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    await fetch(
      "https://ailessonplan.com/core/api/ai-modulars_create",
      requestOptions
    )
      .then(async (response) => {
        let result = await response.json();
        console.log(result, "+========>result");
        if (result.message === "No active subscription found") {
          setOpen(true);
        }
        /*let contentState = null;
        if (containsHTMLTags(result.lesson)) {
          const blocksFromHTML = convertFromHTML(result.lesson);
          contentState = ContentState.createFromBlockArray(
              blocksFromHTML.contentBlocks,
              blocksFromHTML.entityMap
          );
        }
        else {
          contentState = ContentState.createFromText(result.lesson);
        }
        setState(EditorState.createWithContent(contentState));*/

        const htmlString = result.lesson;
        if (containsHTMLTags(htmlString)) {
          setHtmlContent(htmlString);
          setIsTable(true);
          const contentState = ContentState.createFromText(htmlString);
          setState(EditorState.createWithContent(contentState));
        } else {
          const contentState = ContentState.createFromText(htmlString);
          setState(EditorState.createWithContent(contentState));
          setIsTable(false);
        }


        setLoading(false);
      })
      .catch((error) => console.log("error", error));
  };

  return (
    <Container maxWidth="xl">
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Sorry, You have to upgrade your plan
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => navigate("/pricing")}>Upgrade Plan</Button>
        </DialogActions>
      </Dialog>

      <Grid container spacing={2} p={3}>
        <Grid item md={5}>
          <Stack spacing={4}>
            <Button
              onClick={() => navigate("/aimodule")}
              variant="outlined"
              sx={{ width: "10%" }}
            >
              <Box component="img" src="/assets/images/back.png" alt="" />
            </Button>

            <Box>
              <Typography variant="h5" fontWeight={700}>
                {selData.title}
              </Typography>
              <Typography variant="body">{selData.description}</Typography>
              <Typography variant="h6" marginTop={"10px"}>Lesson Title</Typography>
              <TextField
                fullWidth
                variant="outlined"
                InputProps={{
                  style: {
                    borderRadius: "10px",
                  },
                }}
                placeholder="Please input title (only show as the Lesson Header in History)"
                onChange={(e) => setTitle(e.target.value)}
              />
            </Box>

            {JSON.parse(selData.fields).map((item, index) => (
              <Box key={index}>
                {item.type === "textfield" && (
                  <>
                    <Typography variant="h6">{item.label}</Typography>
                    <TextField
                      fullWidth
                      variant="outlined"
                      InputProps={{
                        style: {
                          borderRadius: "10px",
                        },
                      }}
                      placeholder={item.holder}
                      onChange={(e) =>
                        setGenData({
                          ...genData,
                          [item.holder]: e.target.value,
                        })
                      }
                    />
                  </>
                )}
                {item.type === "textarea" && (
                  <>
                    <Typography variant="h6">{item.label}</Typography>
                    <Box
                      component="textarea"
                      width="100%"
                      height="auto"
                      border="1px solid #828282"
                      borderRadius="10px"
                      placeholder={item.holder}
                      p={1}
                      onChange={(e) =>
                        setGenData({
                          ...genData,
                          [item.holder]: e.target.value,
                        })
                      }
                    />
                  </>
                )}
                {item.type === "dropdown" && (
                  <>
                    <Typography variant="h6">{item.label}</Typography>
                    <TextField
                      select
                      defaultValue=""
                      fullWidth
                      variant="outlined"
                      InputProps={{
                        style: {
                          borderRadius: "10px",
                        },
                      }}
                      onChange={(e) =>
                        setGenData({
                          ...genData,
                          [item.holder]: e.target.value,
                        })
                      }
                    >
                      {item.options.map((item1, index1) =>
                              item1 && (
                                  <MenuItem key={index1} value={item1}>
                                    {item1}
                                  </MenuItem>
                              )
                      )}
                    </TextField>
                  </>
                )}
              </Box>
            ))}

            <Button
              onClick={handleGenerate}
              variant="contained"
              size="large"
              disabled={loading}
              sx={{ fontWeight: 700, fontSize: 20 }}
            >
              G e n e r a t e
            </Button>
          </Stack>
        </Grid>

        <Grid item md={7}>
          {!loading ? (
            <Stack
              display={{ md: "flex", sm: "none", xs: "none" }}
              sx={{
                minHeight: "calc(100vh - 90px)",
                height: "100%",
                overflow: "auto",
                border: "1px solid #e0e0e0",
                borderRadius: "8px",
                p: 2,
              }}
            >
              <Box flexGrow={1}>
                <div>
                  {isTable ? (
                      <div dangerouslySetInnerHTML={{__html: htmlContent}}/>
                  ) : (
                      <Editor
                          toolbar={{
                            options: [
                              "inline",
                              "blockType",
                              "fontSize",
                              "fontFamily",
                              "list",
                              "textAlign",
                              "remove",
                              "history",
                            ],
                            inline: {inDropdown: true},
                            list: {inDropdown: true},
                            textAlign: {inDropdown: true},
                            link: {inDropdown: false},
                            history: {inDropdown: true},
                          }}
                          editorState={state}
                          onEditorStateChange={onEditorStateChange}
                      />
                  )}
                </div>
              </Box>
              <TextField
                  placeholder="Regenerate content"
                  fullWidth
                  InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                          <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="32"
                              height="32"
                              viewstack="0 0 32 32"
                              fill="none"
                          >
                            <path
                                d="M13.4971 18.1351L21.4365 9.96203M29.5582 4.97416L22.8565 27.3955C22.2559 29.4049 21.9554 30.4101 21.4374 30.7432C20.9881 31.0322 20.4318 31.0806 19.9424 30.8715C19.3782 30.6305 18.9202 29.6893 18.0066 27.8083L13.7619 19.069C13.6169 18.7705 13.5443 18.6219 13.4475 18.4926C13.3615 18.3778 13.2624 18.2744 13.1509 18.1859C13.0281 18.0885 12.8861 18.0154 12.6091 17.8729L4.10005 13.4932C2.27284 12.5527 1.35916 12.082 1.12503 11.5011C0.921984 10.9974 0.968291 10.4242 1.24902 9.96166C1.57273 9.42833 2.54909 9.1185 4.50162 8.50004L26.2821 1.60115C27.8172 1.11494 28.585 0.872026 29.1035 1.06797C29.5551 1.23865 29.9111 1.60481 30.0769 2.06972C30.2671 2.6032 30.031 3.39321 29.5592 4.97183L29.5582 4.97416Z"
                                stroke="#5375E2"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                      </svg>
                    </InputAdornment>
                  ),
                }}
                variant="outlined"
              />
            </Stack>
          ) : (
            <LoadingComponent />
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

export default SELModule;
