import HomeLessonList from "../../../components/Home/HomeLessonList";
import {useParams} from "react-router-dom";


function LessonTab() {
    let {payment} = useParams();
    return(
      <>
          <HomeLessonList payment={payment}/>
      </>
    );
}

export default LessonTab;