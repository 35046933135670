import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { Box } from '@mui/system';

export default function SpecialOffer() {
    const [open, setOpen] = useState(false); // initially set to false
    const [token, setToken] = useState(localStorage.getItem("token"));

    useEffect(() => {
        //console.log("Token = ", token)
        // Fetch data from the API when component mounts
        fetch('https://ailessonplan.com/core/api/active_plane', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
        })
            .then(response => response.json())
            .then(data => {
                if (data.plane_name === "trial" || data.message === 'No active subscription found') {
                    setOpen(true); // open the dialog if conditions met
                }
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, []);

    const handleAction = () => {
        setOpen(false);
        window.location.href = "https://specialoffer.ailessonplan.com/bts";
    };

    return (
        <Dialog open={open} onClose={() => {setOpen(false)}} maxWidth="sm" fullWidth>
            <DialogTitle>
                <Box textAlign="center" fontSize="1.5rem" fontWeight="bold" color="#e57373">
                    Celebrate Summer
                </Box>
            </DialogTitle>
            <DialogContent>
                <DialogContentText align="center" color="textSecondary">
                    By Giving Yourself A Shorter Work Week During The Next School Year!
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="secondary"
                    size="large"
                    fullWidth
                    onClick={handleAction}
                >
                    SAVE 87% ON 12 MONTHS OF ACCESS TO AILESSONPLAN.COM
                </Button>
            </DialogActions>
        </Dialog>
    );
}
