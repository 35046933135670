function HeroSection() {
    return(
      <>
          {/* ====== Hero Section Start */}
          <div id="home" className="relative overflow-hidden bg-primary pt-[120px] md:pt-[130px] lg:pt-[160px]">
              <div className="container">
                  <div className="-mx-4 flex flex-wrap items-center">
                      <div className="w-full px-4">
                          <div className="hero-content wow fadeInUp mx-auto max-w-[780px] text-center" data-wow-delay=".2s">
                              <h1 className="mb-8 text-3xl font-bold leading-snug text-white sm:text-4xl sm:leading-snug md:text-[45px] md:leading-snug">
                                  Create world-class lesson plans 10X faster with our cutting-edge <font color="black">AI</font>
                              </h1>
                              <p className="mx-auto mb-10 max-w-[600px] text-base text-[#e4e4e4] sm:text-lg sm:leading-relaxed md:text-xl md:leading-relaxed">
                                  Our generative AI platform allows educators like you to create custom, age-specific, lesson plans tailored to your class in a fraction of the time, giving you hours back every day.
                              </p>
                              <ul className="mb-10 flex flex-wrap items-center justify-center">
                                  <li>
                                      <a href="https://ailessonplan.com/signup" className="inline-flex items-center justify-center rounded-lg bg-white py-4 px-6 text-center text-base font-medium text-dark transition duration-300 ease-in-out hover:text-primary hover:shadow-lg sm:px-10">
                                          FREE TRIAL
                                      </a>
                                  </li>
                                  <li>
                                      <a href="https://ailessonplan.com/signup" target="_blank" className="flex items-center py-4 px-6 text-base font-medium text-white transition duration-300 ease-in-out hover:opacity-70 sm:px-10">
                                          START NOW
                                          <span className="pl-2">
                          <svg width={20} height={8} viewBox="0 0 20 8" className="fill-current">
                            <path d="M19.2188 2.90632L17.0625 0.343819C16.875 0.125069 16.5312 0.0938193 16.2812 0.281319C16.0625 0.468819 16.0312 0.812569 16.2188 1.06257L18.25 3.46882H0.9375C0.625 3.46882 0.375 3.71882 0.375 4.03132C0.375 4.34382 0.625 4.59382 0.9375 4.59382H18.25L16.2188 7.00007C16.0312 7.21882 16.0625 7.56257 16.2812 7.78132C16.375 7.87507 16.5 7.90632 16.625 7.90632C16.7812 7.90632 16.9375 7.84382 17.0312 7.71882L19.1875 5.15632C19.75 4.46882 19.75 3.53132 19.2188 2.90632Z" />
                          </svg>
                        </span>
                                      </a>
                                  </li>
                              </ul>
{/*                              <div className="wow fadeInUp text-center" data-wow-delay=".3s">
                                  <img src="assets/images/hero/brand.svg" alt="image" className="mx-auto w-full max-w-[250px] opacity-50 transition duration-300 ease-in-out hover:opacity-100" />
                              </div>*/}
                          </div>
                      </div>
                      <div className="w-full px-4">
                          <div className="wow fadeInUp relative z-10 mx-auto max-w-[845px]" data-wow-delay=".25s">
{/*                              <div className="mt-16">
                                  <img src="assets/images/hero/hero-image.jpg" alt="hero" className="mx-auto max-w-full rounded-t-xl rounded-tr-xl" />
                              </div>*/}
                              {/*<div className="absolute bottom-0 -left-9 z-[-1]">
                                  <svg width={134} height={106} viewBox="0 0 134 106" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <circle cx="1.66667" cy={104} r="1.66667" transform="rotate(-90 1.66667 104)" fill="white" />
                                      <circle cx="16.3333" cy={104} r="1.66667" transform="rotate(-90 16.3333 104)" fill="white" />
                                      <circle cx={31} cy={104} r="1.66667" transform="rotate(-90 31 104)" fill="white" />
                                      <circle cx="45.6667" cy={104} r="1.66667" transform="rotate(-90 45.6667 104)" fill="white" />
                                      <circle cx="60.3333" cy={104} r="1.66667" transform="rotate(-90 60.3333 104)" fill="white" />
                                      <circle cx="88.6667" cy={104} r="1.66667" transform="rotate(-90 88.6667 104)" fill="white" />
                                      <circle cx="117.667" cy={104} r="1.66667" transform="rotate(-90 117.667 104)" fill="white" />
                                      <circle cx="74.6667" cy={104} r="1.66667" transform="rotate(-90 74.6667 104)" fill="white" />
                                      <circle cx={103} cy={104} r="1.66667" transform="rotate(-90 103 104)" fill="white" />
                                      <circle cx={132} cy={104} r="1.66667" transform="rotate(-90 132 104)" fill="white" />
                                      <circle cx="1.66667" cy="89.3333" r="1.66667" transform="rotate(-90 1.66667 89.3333)" fill="white" />
                                      <circle cx="16.3333" cy="89.3333" r="1.66667" transform="rotate(-90 16.3333 89.3333)" fill="white" />
                                      <circle cx={31} cy="89.3333" r="1.66667" transform="rotate(-90 31 89.3333)" fill="white" />
                                      <circle cx="45.6667" cy="89.3333" r="1.66667" transform="rotate(-90 45.6667 89.3333)" fill="white" />
                                      <circle cx="60.3333" cy="89.3338" r="1.66667" transform="rotate(-90 60.3333 89.3338)" fill="white" />
                                      <circle cx="88.6667" cy="89.3338" r="1.66667" transform="rotate(-90 88.6667 89.3338)" fill="white" />
                                      <circle cx="117.667" cy="89.3338" r="1.66667" transform="rotate(-90 117.667 89.3338)" fill="white" />
                                      <circle cx="74.6667" cy="89.3338" r="1.66667" transform="rotate(-90 74.6667 89.3338)" fill="white" />
                                      <circle cx={103} cy="89.3338" r="1.66667" transform="rotate(-90 103 89.3338)" fill="white" />
                                      <circle cx={132} cy="89.3338" r="1.66667" transform="rotate(-90 132 89.3338)" fill="white" />
                                      <circle cx="1.66667" cy="74.6673" r="1.66667" transform="rotate(-90 1.66667 74.6673)" fill="white" />
                                      <circle cx="1.66667" cy="31.0003" r="1.66667" transform="rotate(-90 1.66667 31.0003)" fill="white" />
                                      <circle cx="16.3333" cy="74.6668" r="1.66667" transform="rotate(-90 16.3333 74.6668)" fill="white" />
                                      <circle cx="16.3333" cy="31.0003" r="1.66667" transform="rotate(-90 16.3333 31.0003)" fill="white" />
                                      <circle cx={31} cy="74.6668" r="1.66667" transform="rotate(-90 31 74.6668)" fill="white" />
                                      <circle cx={31} cy="31.0003" r="1.66667" transform="rotate(-90 31 31.0003)" fill="white" />
                                      <circle cx="45.6667" cy="74.6668" r="1.66667" transform="rotate(-90 45.6667 74.6668)" fill="white" />
                                      <circle cx="45.6667" cy="31.0003" r="1.66667" transform="rotate(-90 45.6667 31.0003)" fill="white" />
                                      <circle cx="60.3333" cy="74.6668" r="1.66667" transform="rotate(-90 60.3333 74.6668)" fill="white" />
                                      <circle cx="60.3333" cy="31.0001" r="1.66667" transform="rotate(-90 60.3333 31.0001)" fill="white" />
                                      <circle cx="88.6667" cy="74.6668" r="1.66667" transform="rotate(-90 88.6667 74.6668)" fill="white" />
                                      <circle cx="88.6667" cy="31.0001" r="1.66667" transform="rotate(-90 88.6667 31.0001)" fill="white" />
                                      <circle cx="117.667" cy="74.6668" r="1.66667" transform="rotate(-90 117.667 74.6668)" fill="white" />
                                      <circle cx="117.667" cy="31.0001" r="1.66667" transform="rotate(-90 117.667 31.0001)" fill="white" />
                                      <circle cx="74.6667" cy="74.6668" r="1.66667" transform="rotate(-90 74.6667 74.6668)" fill="white" />
                                      <circle cx="74.6667" cy="31.0001" r="1.66667" transform="rotate(-90 74.6667 31.0001)" fill="white" />
                                      <circle cx={103} cy="74.6668" r="1.66667" transform="rotate(-90 103 74.6668)" fill="white" />
                                      <circle cx={103} cy="31.0001" r="1.66667" transform="rotate(-90 103 31.0001)" fill="white" />
                                      <circle cx={132} cy="74.6668" r="1.66667" transform="rotate(-90 132 74.6668)" fill="white" />
                                      <circle cx={132} cy="31.0001" r="1.66667" transform="rotate(-90 132 31.0001)" fill="white" />
                                      <circle cx="1.66667" cy="60.0003" r="1.66667" transform="rotate(-90 1.66667 60.0003)" fill="white" />
                                      <circle cx="1.66667" cy="16.3336" r="1.66667" transform="rotate(-90 1.66667 16.3336)" fill="white" />
                                      <circle cx="16.3333" cy="60.0003" r="1.66667" transform="rotate(-90 16.3333 60.0003)" fill="white" />
                                      <circle cx="16.3333" cy="16.3336" r="1.66667" transform="rotate(-90 16.3333 16.3336)" fill="white" />
                                      <circle cx={31} cy="60.0003" r="1.66667" transform="rotate(-90 31 60.0003)" fill="white" />
                                      <circle cx={31} cy="16.3336" r="1.66667" transform="rotate(-90 31 16.3336)" fill="white" />
                                      <circle cx="45.6667" cy="60.0003" r="1.66667" transform="rotate(-90 45.6667 60.0003)" fill="white" />
                                      <circle cx="45.6667" cy="16.3336" r="1.66667" transform="rotate(-90 45.6667 16.3336)" fill="white" />
                                      <circle cx="60.3333" cy="60.0003" r="1.66667" transform="rotate(-90 60.3333 60.0003)" fill="white" />
                                      <circle cx="60.3333" cy="16.3336" r="1.66667" transform="rotate(-90 60.3333 16.3336)" fill="white" />
                                      <circle cx="88.6667" cy="60.0003" r="1.66667" transform="rotate(-90 88.6667 60.0003)" fill="white" />
                                      <circle cx="88.6667" cy="16.3336" r="1.66667" transform="rotate(-90 88.6667 16.3336)" fill="white" />
                                      <circle cx="117.667" cy="60.0003" r="1.66667" transform="rotate(-90 117.667 60.0003)" fill="white" />
                                      <circle cx="117.667" cy="16.3336" r="1.66667" transform="rotate(-90 117.667 16.3336)" fill="white" />
                                      <circle cx="74.6667" cy="60.0003" r="1.66667" transform="rotate(-90 74.6667 60.0003)" fill="white" />
                                      <circle cx="74.6667" cy="16.3336" r="1.66667" transform="rotate(-90 74.6667 16.3336)" fill="white" />
                                      <circle cx={103} cy="60.0003" r="1.66667" transform="rotate(-90 103 60.0003)" fill="white" />
                                      <circle cx={103} cy="16.3336" r="1.66667" transform="rotate(-90 103 16.3336)" fill="white" />
                                      <circle cx={132} cy="60.0003" r="1.66667" transform="rotate(-90 132 60.0003)" fill="white" />
                                      <circle cx={132} cy="16.3336" r="1.66667" transform="rotate(-90 132 16.3336)" fill="white" />
                                      <circle cx="1.66667" cy="45.3336" r="1.66667" transform="rotate(-90 1.66667 45.3336)" fill="white" />
                                      <circle cx="1.66667" cy="1.66683" r="1.66667" transform="rotate(-90 1.66667 1.66683)" fill="white" />
                                      <circle cx="16.3333" cy="45.3336" r="1.66667" transform="rotate(-90 16.3333 45.3336)" fill="white" />
                                      <circle cx="16.3333" cy="1.66683" r="1.66667" transform="rotate(-90 16.3333 1.66683)" fill="white" />
                                      <circle cx={31} cy="45.3336" r="1.66667" transform="rotate(-90 31 45.3336)" fill="white" />
                                      <circle cx={31} cy="1.66683" r="1.66667" transform="rotate(-90 31 1.66683)" fill="white" />
                                      <circle cx="45.6667" cy="45.3336" r="1.66667" transform="rotate(-90 45.6667 45.3336)" fill="white" />
                                      <circle cx="45.6667" cy="1.66683" r="1.66667" transform="rotate(-90 45.6667 1.66683)" fill="white" />
                                      <circle cx="60.3333" cy="45.3338" r="1.66667" transform="rotate(-90 60.3333 45.3338)" fill="white" />
                                      <circle cx="60.3333" cy="1.66707" r="1.66667" transform="rotate(-90 60.3333 1.66707)" fill="white" />
                                      <circle cx="88.6667" cy="45.3338" r="1.66667" transform="rotate(-90 88.6667 45.3338)" fill="white" />
                                      <circle cx="88.6667" cy="1.66707" r="1.66667" transform="rotate(-90 88.6667 1.66707)" fill="white" />
                                      <circle cx="117.667" cy="45.3338" r="1.66667" transform="rotate(-90 117.667 45.3338)" fill="white" />
                                      <circle cx="117.667" cy="1.66707" r="1.66667" transform="rotate(-90 117.667 1.66707)" fill="white" />
                                      <circle cx="74.6667" cy="45.3338" r="1.66667" transform="rotate(-90 74.6667 45.3338)" fill="white" />
                                      <circle cx="74.6667" cy="1.66707" r="1.66667" transform="rotate(-90 74.6667 1.66707)" fill="white" />
                                      <circle cx={103} cy="45.3338" r="1.66667" transform="rotate(-90 103 45.3338)" fill="white" />
                                      <circle cx={103} cy="1.66707" r="1.66667" transform="rotate(-90 103 1.66707)" fill="white" />
                                      <circle cx={132} cy="45.3338" r="1.66667" transform="rotate(-90 132 45.3338)" fill="white" />
                                      <circle cx={132} cy="1.66707" r="1.66667" transform="rotate(-90 132 1.66707)" fill="white" />
                                  </svg>
                              </div>
                              <div className="absolute -top-6 -right-6 z-[-1]">
                                  <svg width={134} height={106} viewBox="0 0 134 106" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <circle cx="1.66667" cy={104} r="1.66667" transform="rotate(-90 1.66667 104)" fill="white" />
                                      <circle cx="16.3333" cy={104} r="1.66667" transform="rotate(-90 16.3333 104)" fill="white" />
                                      <circle cx={31} cy={104} r="1.66667" transform="rotate(-90 31 104)" fill="white" />
                                      <circle cx="45.6667" cy={104} r="1.66667" transform="rotate(-90 45.6667 104)" fill="white" />
                                      <circle cx="60.3333" cy={104} r="1.66667" transform="rotate(-90 60.3333 104)" fill="white" />
                                      <circle cx="88.6667" cy={104} r="1.66667" transform="rotate(-90 88.6667 104)" fill="white" />
                                      <circle cx="117.667" cy={104} r="1.66667" transform="rotate(-90 117.667 104)" fill="white" />
                                      <circle cx="74.6667" cy={104} r="1.66667" transform="rotate(-90 74.6667 104)" fill="white" />
                                      <circle cx={103} cy={104} r="1.66667" transform="rotate(-90 103 104)" fill="white" />
                                      <circle cx={132} cy={104} r="1.66667" transform="rotate(-90 132 104)" fill="white" />
                                      <circle cx="1.66667" cy="89.3333" r="1.66667" transform="rotate(-90 1.66667 89.3333)" fill="white" />
                                      <circle cx="16.3333" cy="89.3333" r="1.66667" transform="rotate(-90 16.3333 89.3333)" fill="white" />
                                      <circle cx={31} cy="89.3333" r="1.66667" transform="rotate(-90 31 89.3333)" fill="white" />
                                      <circle cx="45.6667" cy="89.3333" r="1.66667" transform="rotate(-90 45.6667 89.3333)" fill="white" />
                                      <circle cx="60.3333" cy="89.3338" r="1.66667" transform="rotate(-90 60.3333 89.3338)" fill="white" />
                                      <circle cx="88.6667" cy="89.3338" r="1.66667" transform="rotate(-90 88.6667 89.3338)" fill="white" />
                                      <circle cx="117.667" cy="89.3338" r="1.66667" transform="rotate(-90 117.667 89.3338)" fill="white" />
                                      <circle cx="74.6667" cy="89.3338" r="1.66667" transform="rotate(-90 74.6667 89.3338)" fill="white" />
                                      <circle cx={103} cy="89.3338" r="1.66667" transform="rotate(-90 103 89.3338)" fill="white" />
                                      <circle cx={132} cy="89.3338" r="1.66667" transform="rotate(-90 132 89.3338)" fill="white" />
                                      <circle cx="1.66667" cy="74.6673" r="1.66667" transform="rotate(-90 1.66667 74.6673)" fill="white" />
                                      <circle cx="1.66667" cy="31.0003" r="1.66667" transform="rotate(-90 1.66667 31.0003)" fill="white" />
                                      <circle cx="16.3333" cy="74.6668" r="1.66667" transform="rotate(-90 16.3333 74.6668)" fill="white" />
                                      <circle cx="16.3333" cy="31.0003" r="1.66667" transform="rotate(-90 16.3333 31.0003)" fill="white" />
                                      <circle cx={31} cy="74.6668" r="1.66667" transform="rotate(-90 31 74.6668)" fill="white" />
                                      <circle cx={31} cy="31.0003" r="1.66667" transform="rotate(-90 31 31.0003)" fill="white" />
                                      <circle cx="45.6667" cy="74.6668" r="1.66667" transform="rotate(-90 45.6667 74.6668)" fill="white" />
                                      <circle cx="45.6667" cy="31.0003" r="1.66667" transform="rotate(-90 45.6667 31.0003)" fill="white" />
                                      <circle cx="60.3333" cy="74.6668" r="1.66667" transform="rotate(-90 60.3333 74.6668)" fill="white" />
                                      <circle cx="60.3333" cy="31.0001" r="1.66667" transform="rotate(-90 60.3333 31.0001)" fill="white" />
                                      <circle cx="88.6667" cy="74.6668" r="1.66667" transform="rotate(-90 88.6667 74.6668)" fill="white" />
                                      <circle cx="88.6667" cy="31.0001" r="1.66667" transform="rotate(-90 88.6667 31.0001)" fill="white" />
                                      <circle cx="117.667" cy="74.6668" r="1.66667" transform="rotate(-90 117.667 74.6668)" fill="white" />
                                      <circle cx="117.667" cy="31.0001" r="1.66667" transform="rotate(-90 117.667 31.0001)" fill="white" />
                                      <circle cx="74.6667" cy="74.6668" r="1.66667" transform="rotate(-90 74.6667 74.6668)" fill="white" />
                                      <circle cx="74.6667" cy="31.0001" r="1.66667" transform="rotate(-90 74.6667 31.0001)" fill="white" />
                                      <circle cx={103} cy="74.6668" r="1.66667" transform="rotate(-90 103 74.6668)" fill="white" />
                                      <circle cx={103} cy="31.0001" r="1.66667" transform="rotate(-90 103 31.0001)" fill="white" />
                                      <circle cx={132} cy="74.6668" r="1.66667" transform="rotate(-90 132 74.6668)" fill="white" />
                                      <circle cx={132} cy="31.0001" r="1.66667" transform="rotate(-90 132 31.0001)" fill="white" />
                                      <circle cx="1.66667" cy="60.0003" r="1.66667" transform="rotate(-90 1.66667 60.0003)" fill="white" />
                                      <circle cx="1.66667" cy="16.3336" r="1.66667" transform="rotate(-90 1.66667 16.3336)" fill="white" />
                                      <circle cx="16.3333" cy="60.0003" r="1.66667" transform="rotate(-90 16.3333 60.0003)" fill="white" />
                                      <circle cx="16.3333" cy="16.3336" r="1.66667" transform="rotate(-90 16.3333 16.3336)" fill="white" />
                                      <circle cx={31} cy="60.0003" r="1.66667" transform="rotate(-90 31 60.0003)" fill="white" />
                                      <circle cx={31} cy="16.3336" r="1.66667" transform="rotate(-90 31 16.3336)" fill="white" />
                                      <circle cx="45.6667" cy="60.0003" r="1.66667" transform="rotate(-90 45.6667 60.0003)" fill="white" />
                                      <circle cx="45.6667" cy="16.3336" r="1.66667" transform="rotate(-90 45.6667 16.3336)" fill="white" />
                                      <circle cx="60.3333" cy="60.0003" r="1.66667" transform="rotate(-90 60.3333 60.0003)" fill="white" />
                                      <circle cx="60.3333" cy="16.3336" r="1.66667" transform="rotate(-90 60.3333 16.3336)" fill="white" />
                                      <circle cx="88.6667" cy="60.0003" r="1.66667" transform="rotate(-90 88.6667 60.0003)" fill="white" />
                                      <circle cx="88.6667" cy="16.3336" r="1.66667" transform="rotate(-90 88.6667 16.3336)" fill="white" />
                                      <circle cx="117.667" cy="60.0003" r="1.66667" transform="rotate(-90 117.667 60.0003)" fill="white" />
                                      <circle cx="117.667" cy="16.3336" r="1.66667" transform="rotate(-90 117.667 16.3336)" fill="white" />
                                      <circle cx="74.6667" cy="60.0003" r="1.66667" transform="rotate(-90 74.6667 60.0003)" fill="white" />
                                      <circle cx="74.6667" cy="16.3336" r="1.66667" transform="rotate(-90 74.6667 16.3336)" fill="white" />
                                      <circle cx={103} cy="60.0003" r="1.66667" transform="rotate(-90 103 60.0003)" fill="white" />
                                      <circle cx={103} cy="16.3336" r="1.66667" transform="rotate(-90 103 16.3336)" fill="white" />
                                      <circle cx={132} cy="60.0003" r="1.66667" transform="rotate(-90 132 60.0003)" fill="white" />
                                      <circle cx={132} cy="16.3336" r="1.66667" transform="rotate(-90 132 16.3336)" fill="white" />
                                      <circle cx="1.66667" cy="45.3336" r="1.66667" transform="rotate(-90 1.66667 45.3336)" fill="white" />
                                      <circle cx="1.66667" cy="1.66683" r="1.66667" transform="rotate(-90 1.66667 1.66683)" fill="white" />
                                      <circle cx="16.3333" cy="45.3336" r="1.66667" transform="rotate(-90 16.3333 45.3336)" fill="white" />
                                      <circle cx="16.3333" cy="1.66683" r="1.66667" transform="rotate(-90 16.3333 1.66683)" fill="white" />
                                      <circle cx={31} cy="45.3336" r="1.66667" transform="rotate(-90 31 45.3336)" fill="white" />
                                      <circle cx={31} cy="1.66683" r="1.66667" transform="rotate(-90 31 1.66683)" fill="white" />
                                      <circle cx="45.6667" cy="45.3336" r="1.66667" transform="rotate(-90 45.6667 45.3336)" fill="white" />
                                      <circle cx="45.6667" cy="1.66683" r="1.66667" transform="rotate(-90 45.6667 1.66683)" fill="white" />
                                      <circle cx="60.3333" cy="45.3338" r="1.66667" transform="rotate(-90 60.3333 45.3338)" fill="white" />
                                      <circle cx="60.3333" cy="1.66707" r="1.66667" transform="rotate(-90 60.3333 1.66707)" fill="white" />
                                      <circle cx="88.6667" cy="45.3338" r="1.66667" transform="rotate(-90 88.6667 45.3338)" fill="white" />
                                      <circle cx="88.6667" cy="1.66707" r="1.66667" transform="rotate(-90 88.6667 1.66707)" fill="white" />
                                      <circle cx="117.667" cy="45.3338" r="1.66667" transform="rotate(-90 117.667 45.3338)" fill="white" />
                                      <circle cx="117.667" cy="1.66707" r="1.66667" transform="rotate(-90 117.667 1.66707)" fill="white" />
                                      <circle cx="74.6667" cy="45.3338" r="1.66667" transform="rotate(-90 74.6667 45.3338)" fill="white" />
                                      <circle cx="74.6667" cy="1.66707" r="1.66667" transform="rotate(-90 74.6667 1.66707)" fill="white" />
                                      <circle cx={103} cy="45.3338" r="1.66667" transform="rotate(-90 103 45.3338)" fill="white" />
                                      <circle cx={103} cy="1.66707" r="1.66667" transform="rotate(-90 103 1.66707)" fill="white" />
                                      <circle cx={132} cy="45.3338" r="1.66667" transform="rotate(-90 132 45.3338)" fill="white" />
                                      <circle cx={132} cy="1.66707" r="1.66667" transform="rotate(-90 132 1.66707)" fill="white" />
                                  </svg>
                              </div>*/}
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          {/* ====== Hero Section End */}
      </>
    );
}

export default HeroSection;