import Header from "../../components/LandingPage/Header";
import NavBar from "../../components/LandingPage/NavBar";
import Footer from "../../components/LandingPage/Footer";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import * as React from "react";
import LoadingComponent from "../../components/Boost/LoadingComponent";
import NewHeader from "../../components/LandingPage/NewHeader";
import { IconButton, useMediaQuery } from "@mui/material";

import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";

function Signup() {
  const navigate = useNavigate();
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [loggedIn, setLoggedIn] = useState(false);
  const [in_progress, set_in_progress] = useState(false);
  const [login_pressed, set_login_pressed] = useState(false);
  const [checkBox, setCheckBox] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    password_confirmation: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);

  const isMobile = useMediaQuery("(max-width:600px)");

  const [errors, setErrors] = useState([]);

  async function validateToken() {
    try {
      set_in_progress(true);
      const res = await axios.get("https://ailessonplan.com/core/api/user", {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      });
      if (!res?.data) return;
      console.log(res.data, "==>res.data");
      if (res.data.id) {
        setLoggedIn(true);
        navigate("/lessons"); //'https://ailessonplan.com/lessons';
      } else {
        setLoggedIn(false);
        setToken(null);
        localStorage.removeItem("token"); // Remove invalid token from local storage
      }
      set_in_progress(false);
    } catch (err) {
      console.error(err);
      return;
    }
    // console.log("Token in validation: ", token);

    // await fetch("https://ailessonplan.com/core/api/user", {
    //   headers: {
    //     Authorization: `Bearer ${token}`,
    //     Accept: "application/json",
    //   },
    // })
    //   .then((response) => response.json())
    //   .then((data) => {
    //     //console.log("Data: ", data);
    //     if (data.id) {
    //       setLoggedIn(true);
    //       navigate("/lessons"); //'https://ailessonplan.com/lessons';
    //     } else {
    //       setLoggedIn(false);
    //       setToken(null);
    //       localStorage.removeItem("token"); // Remove invalid token from local storage
    //     }
    //     set_in_progress(false);
    //   });
  }

  useEffect(() => {
    //console.log("Use effect started");
    //console.log("Token URL: ", tokenURL);

    if (token) {
      //console.log("Yes token = ", token);
      validateToken();
    } else {
      //console.log("No token the login is false");
      setLoggedIn(false);
    }
  }, [token]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (checkBox === false) {
      return;
    }

    axios
      .post("https://ailessonplan.com/core/api/signup", formData)
      .then((response) => {
        // handle success
        console.log(response.data);
        navigate("/signing", { state: { new_register: true } });
      })
      .catch((error) => {
        // handle error
        console.log(error.response.data);
        setErrors(error.response.data.errors);
      });
  };

  function handle_on_signin_clicked() {
    navigate("/signing");
  }

  return in_progress ? (
    <>
      <LoadingComponent />
    </>
  ) : (
    <>
      <Helmet>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-M8647N6J28"
        ></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-M8647N6J28');
          `}
        </script>
      </Helmet>
      {/* <NavBar /> */}
      {/* ====== Banner Section Start */}
      {/* <div className="relative z-10 overflow-hidden bg-primary pt-[120px] pb-[100px] md:pt-[130px] lg:pt-[160px]">
        <div className="container">
          <div className="-mx-4 flex flex-wrap items-center">
            <div className="w-full px-4">
              <div className="text-center">
                <h1 className="text-4xl font-semibold text-white">
                  Register Page
                </h1>
              </div>
            </div>
          </div>
        </div>
        <div>
          <span className="absolute top-0 left-0 z-[-1]">
            <svg
              width={495}
              height={470}
              viewBox="0 0 495 470"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx={55}
                cy={442}
                r={138}
                stroke="white"
                strokeOpacity="0.04"
                strokeWidth={50}
              />
              <circle
                cx={446}
                r={39}
                stroke="white"
                strokeOpacity="0.04"
                strokeWidth={20}
              />
              <path
                d="M245.406 137.609L233.985 94.9852L276.609 106.406L245.406 137.609Z"
                stroke="white"
                strokeOpacity="0.08"
                strokeWidth={12}
              />
            </svg>
          </span>
          <span className="absolute top-0 right-0 z-[-1]">
            <svg
              width={493}
              height={470}
              viewBox="0 0 493 470"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx={462}
                cy={5}
                r={138}
                stroke="white"
                strokeOpacity="0.04"
                strokeWidth={50}
              />
              <circle
                cx={49}
                cy={470}
                r={39}
                stroke="white"
                strokeOpacity="0.04"
                strokeWidth={20}
              />
              <path
                d="M222.393 226.701L272.808 213.192L259.299 263.607L222.393 226.701Z"
                stroke="white"
                strokeOpacity="0.06"
                strokeWidth={13}
              />
            </svg>
          </span>
        </div>
      </div> */}
      {/* ====== Banner Section End */}
      {/* ====== Forms Section Start */}
      <section className="flex items-center h-screen bg-[#F4F7FF] py-14 lg:py-10">
        <div className="container">
          <div className="-mx-4 flex flex-wrap w-full px-4">
            {!isMobile && (
              <div className="flex items-center justify-center rounded-s-lg w-1/2 bg-[#27b7c4]">
                <img
                  src="/assets/images/Group 133.png"
                  alt=""
                  className="w-1/2 h-1/2"
                />
              </div>
            )}
            <div
              className="wow md:w-1/2 xs:w-full rounded-s-lg fadeInUp relative mx-auto overflow-hidden rounded-lg bg-white py-14 px-8 text-center sm:px-12 md:px-[60px]"
              data-wow-delay=".15s"
            >
              <div className="mb-10 text-center">
                <p className="text-[30px] text-sky-500 font-bold">Register</p>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="mb-6 relative">
                  <p className="float-left text-xl mb-2 text-[#959AA0]">
                    &nbsp;Full Name
                  </p>
                  <input
                    onChange={handleChange}
                    id={"name"}
                    name={"name"}
                    type="text"
                    placeholder="Enter your name"
                    className="bordder-[#E9EDF4] w-full rounded-md border bg-[#FCFDFE] py-3 px-5 text-base text-body-color placeholder-[#ACB6BE] outline-none transition focus:border-primary focus-visible:shadow-none"
                  />
                  {errors.name && (
                    <div className="text-red-500">{errors.name[0]}</div>
                  )}
                  <img
                    src="/assets/images/person.png"
                    alt=""
                    className="absolute top-[50px] right-2"
                  />
                </div>
                <div className="mb-6 relative">
                  <p className="float-left text-xl mb-2 text-[#959AA0]">
                    &nbsp;Email
                  </p>
                  <input
                    onChange={handleChange}
                    id={"email"}
                    name={"email"}
                    type="email"
                    placeholder="Enter your email"
                    className="bordder-[#E9EDF4] w-full rounded-md border bg-[#FCFDFE] py-3 px-5 text-base text-body-color placeholder-[#ACB6BE] outline-none transition focus:border-primary focus-visible:shadow-none"
                  />
                  {errors.email && (
                    <div className="text-red-500">{errors.email[0]}</div>
                  )}
                  <img
                    src="/assets/images/Vector.png"
                    alt=""
                    className="absolute top-[50px] right-2"
                  />
                </div>
                <div className="mb-6 relative">
                  <p className="float-left text-xl mb-2 text-[#959AA0]">
                    &nbsp;Password
                  </p>
                  <input
                    onChange={handleChange}
                    id={"password"}
                    name={"password"}
                    type={showPassword ? "text" : "password"}
                    placeholder="*********"
                    className="bordder-[#E9EDF4] w-full rounded-md border bg-[#FCFDFE] py-3 px-5 text-base text-body-color placeholder-[#ACB6BE] outline-none transition focus:border-primary focus-visible:shadow-none"
                  />
                  {errors.password && (
                    <div className="text-red-500">{errors.password[0]}</div>
                  )}
                  <div className="absolute top-[50px] right-2 text-[#959AA0]">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      sx={{ bottom: 9 }}
                    >
                      {showPassword ? (
                        <VisibilityOutlinedIcon />
                      ) : (
                        <VisibilityOffOutlinedIcon />
                      )}
                    </IconButton>
                  </div>
                </div>
                <div className="mb-3 relative">
                  <p className="float-left text-xl mb-2 text-[#959AA0]">
                    &nbsp;Confirm Password
                  </p>
                  <input
                    onChange={handleChange}
                    id={"password_confirmation"}
                    name={"password_confirmation"}
                    type={showPassword1 ? "text" : "password"}
                    placeholder="*********"
                    className="bordder-[#E9EDF4] w-full rounded-md border bg-[#FCFDFE] py-3 px-5 text-base text-body-color placeholder-[#ACB6BE] outline-none transition focus:border-primary focus-visible:shadow-none"
                  />
                  {errors.password_confirmation && (
                    <div className="text-red-500">
                      {errors.password_confirmation[0]}
                    </div>
                  )}
                  <div className="absolute top-[50px] right-2 text-[#959AA0]">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword1(!showPassword1)}
                      sx={{ bottom: 9 }}
                    >
                      {showPassword1 ? (
                        <VisibilityOutlinedIcon />
                      ) : (
                        <VisibilityOffOutlinedIcon />
                      )}
                    </IconButton>
                  </div>
                </div>
                <div className="flex mb-6 gap-2">
                  &nbsp;
                  <input
                    type="checkbox"
                    value={checkBox}
                    onClick={() => setCheckBox(!checkBox)}
                  />
                  <p>Confirm that you are agreed with terms and conditions</p>
                </div>
                <div className="mb-10">
                  <input
                    value={"Register"}
                    type="submit"
                    // defaultValue="Sign Up"
                    className="bordder-primary  w-full cursor-pointer rounded-md border bg-primary py-3 px-5 text-base text-white transition duration-300 ease-in-out hover:shadow-md"
                  />
                </div>
              </form>
              <p className="mb-6 text-base text-[#adadad]">OR Connect With</p>
              <ul className="-mx-2 mb-12 flex justify-between">
                <li className="w-full px-2">
                  <a
                    href="https://ailessonplan.com/core/login/facebook"
                    className="flex h-11 items-center justify-center rounded-md bg-[#4064AC] transition hover:bg-opacity-90"
                  >
                    <svg
                      width={10}
                      height={20}
                      viewBox="0 0 10 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.29878 8H7.74898H7.19548V7.35484V5.35484V4.70968H7.74898H8.91133C9.21575 4.70968 9.46483 4.45161 9.46483 4.06452V0.645161C9.46483 0.290323 9.24343 0 8.91133 0H6.89106C4.70474 0 3.18262 1.80645 3.18262 4.48387V7.29032V7.93548H2.62912H0.747223C0.359774 7.93548 0 8.29032 0 8.80645V11.129C0 11.5806 0.304424 12 0.747223 12H2.57377H3.12727V12.6452V19.129C3.12727 19.5806 3.43169 20 3.87449 20H6.47593C6.64198 20 6.78036 19.9032 6.89106 19.7742C7.00176 19.6452 7.08478 19.4194 7.08478 19.2258V12.6774V12.0323H7.66596H8.91133C9.2711 12.0323 9.54785 11.7742 9.6032 11.3871V11.3548V11.3226L9.99065 9.09677C10.0183 8.87097 9.99065 8.6129 9.8246 8.35484C9.76925 8.19355 9.52018 8.03226 9.29878 8Z"
                        fill="white"
                      />
                    </svg>
                  </a>
                </li>
                <li className="w-full px-2">
                  <a
                    href="https://ailessonplan.com/core/login/twitter"
                    className="flex h-11 items-center justify-center rounded-md bg-[#1C9CEA] transition hover:bg-opacity-90"
                  >
                    <svg
                      width={22}
                      height={16}
                      viewBox="0 0 22 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M19.5516 2.75538L20.9 1.25245C21.2903 0.845401 21.3968 0.53229 21.4323 0.375734C20.3677 0.939335 19.3742 1.1272 18.7355 1.1272H18.4871L18.3452 1.00196C17.4935 0.344423 16.429 0 15.2935 0C12.8097 0 10.8581 1.81605 10.8581 3.91389C10.8581 4.03914 10.8581 4.22701 10.8935 4.35225L11 4.97847L10.2548 4.94716C5.7129 4.82192 1.9871 1.37769 1.38387 0.782779C0.390323 2.34834 0.958064 3.85127 1.56129 4.79061L2.76774 6.54403L0.851613 5.6047C0.887097 6.91977 1.45484 7.95303 2.55484 8.7045L3.5129 9.33072L2.55484 9.67515C3.15806 11.272 4.50645 11.9296 5.5 12.18L6.8129 12.4932L5.57097 13.2446C3.58387 14.4971 1.1 14.4031 0 14.3092C2.23548 15.6869 4.89677 16 6.74194 16C8.12581 16 9.15484 15.8748 9.40322 15.7808C19.3387 13.7143 19.8 5.8865 19.8 4.32094V4.10176L20.0129 3.97652C21.2194 2.97456 21.7161 2.44227 22 2.12916C21.8935 2.16047 21.7516 2.22309 21.6097 2.2544L19.5516 2.75538Z"
                        fill="white"
                      />
                    </svg>
                  </a>
                </li>
                <li className="w-full px-2">
                  <a
                    href="https://ailessonplan.com/core/login/google"
                    className="flex h-11 items-center justify-center rounded-md bg-[#D64937] transition hover:bg-opacity-90"
                  >
                    <svg
                      width={18}
                      height={18}
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17.8477 8.17132H9.29628V10.643H15.4342C15.1065 14.0743 12.2461 15.5574 9.47506 15.5574C5.95916 15.5574 2.8306 12.8821 2.8306 9.01461C2.8306 5.29251 5.81018 2.47185 9.47506 2.47185C12.2759 2.47185 13.9742 4.24567 13.9742 4.24567L15.7024 2.47185C15.7024 2.47185 13.3783 0.000145544 9.35587 0.000145544C4.05223 -0.0289334 0 4.30383 0 8.98553C0 13.5218 3.81386 18 9.44526 18C14.4212 18 17.9967 14.7141 17.9967 9.79974C18.0264 8.78198 17.8477 8.17132 17.8477 8.17132Z"
                        fill="white"
                      />
                    </svg>
                  </a>
                </li>
              </ul>
              <p className="text-base text-[#adadad]">
                Already have an account?&nbsp;&nbsp;
                <a
                  className="text-primary hover:underline cursor-pointer"
                  onClick={handle_on_signin_clicked}
                >
                  Sign In
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* ====== Forms Section End */}
      {/* ====== Footer Section Start */}
      {/* ====== Back To Top Start */}
      <a
        href=""
        className="back-to-top fixed bottom-8 right-8 left-auto z-[999] hidden h-10 w-10 items-center justify-center rounded-md bg-primary text-white shadow-md transition duration-300 ease-in-out hover:bg-dark"
      >
        <span className="mt-[6px] h-3 w-3 rotate-45 border-t border-l border-white" />
      </a>
      {/* ====== Back To Top End */}
      {/* ====== All Scripts */}
    </>
  );
}

export default Signup;
