import {useState} from 'react';

const switchStyles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '50px',
    width: '200px',
    border: 'none',
    borderRadius: '25px',
    padding: '0 10px',
    margin: '0 auto',
    fontSize: '20px',
    backgroundColor: '#ccc',
    color: '#fff',
};

const switchStylesActive = {
    ...switchStyles,
    backgroundColor: '#007bff',
};


function Switch({onToggle}) {
    const [isMonthly, setIsMonthly] = useState(true);

    function handleToggle() {
        setIsMonthly(!isMonthly);
        onToggle(isMonthly);
    }


    return (
        <div style={isMonthly ? switchStyles : switchStylesActive}>
            {/*{isMonthly ? 'Monthly' : 'Yearly'}*/}
            <button onClick={handleToggle}>
                {isMonthly ? 'Yearly' : 'Monthly'}
            </button>
        </div>
    );
}

export default Switch;