import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LandingPage from "./pages/LandingPage";
import Signin from "./pages/Auth/Signin";
import Signup from "./pages/Auth/Signup";
import HomePage from "./pages/Home/HomePage";
import LessonTab from "./pages/Home/HomeTabs/LessonTab";
import PricingTab from "./pages/Home/HomeTabs/PricingTab";
import BlogTab from "./pages/Home/HomeTabs/BlogTab";
import Account from "./pages/Home/HomeTabs/Account";
import BlogArticle from "./pages/Home/HomeTabs/BlogArticle";
import ThankYou_Payment from "./pages/Payment/ThankYou_Payment";
import Forgot_Password from "./pages/Auth/Forgot_Password";
import HomeSchooling from "./pages/HomeSchooling";
import Support from "./pages/Home/HomeTabs/Support";
import Aimodule from "./pages/Home/HomeTabs/aimodule";
import SELModule from "./pages/Home/HomeTabs/SELmodule";

import { Provider } from "react-redux";
import store from "./store";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path={"/homeschooling"} element={<HomeSchooling />} />
        <Route path="/signing" element={<Signin />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/forgot-password" element={<Forgot_Password />} />
        <Route element={<HomePage />}>
          <Route path={"lessons"} index element={<LessonTab />} />
          <Route path={"lessons/:payment"} index element={<LessonTab />} />
          <Route path={"pricing"} index element={<PricingTab />} />
          <Route path={"blog"} index element={<BlogTab />} />
          <Route path={"account"} index element={<Account />} />
          <Route path={"contact"} index element={<Support />} />
          <Route path={"aimodule"} index element={<Aimodule />} />
          <Route path={"aimodule/:selmodule"} index element={<SELModule />} />
          <Route path={"blog_article/:id"} index element={<BlogArticle />} />
          <Route path={"/thank_you"} element={<ThankYou_Payment />} />
        </Route>
        <Route path={"/free/blog/:from_free"} element={<BlogTab />} />
        <Route path={"/free/blog_article/:id"} element={<BlogArticle />} />
      </Routes>
    </BrowserRouter>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
