var AuthuserData = null;
export const isAuthenticated = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
        return false;
    }

    const url = 'https://ailessonplan.com/core/api/user';

    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });

        const data = await response.json();

        if (response.ok && data.id) {
            AuthuserData = data;
            return data;
        } else {
            return null;
        }

    } catch (error) {
        console.error(error);
        return false;
    }
};

export function get_auth_user() {
    return AuthuserData;
}