import React, { useEffect } from 'react';
import { FormControl, InputLabel, Select, Input, Chip, MenuItem, Checkbox, ListItemText, Typography } from '@mui/material';

function AdditionalResourcesCheckbox({ selectedItems, setSelectedItems }) {
    const items = [
        'Homework',
        'Worksheet',
        'Rubric',
        'Quiz',
        'Slides',
        'Vocabulary List',
        'Group Discussion Questions',
        'Leaders Guide',
        'Lab/Activities',
        'Research Paper',
        'Reading Comprehension',
        'Differentiation Strategies',
        'Extension Activities',
        'Test Questions',
    ];

    useEffect(() => {
        setSelectedItems([]);
    }, []);

    const handleChange = (event) => {
        if (event.target.value.length <= 3) {
            setSelectedItems(event.target.value);
        }
    };

    return (
        <div>
            <FormControl fullWidth>
                <InputLabel>Additional Resources</InputLabel>
                <Select
                    multiple
                    value={selectedItems}
                    onChange={handleChange}
                    input={<Input />}
                    renderValue={(selected) => (
                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                            {selected.map((value) => (
                                <Chip
                                    key={value}
                                    label={value}
                                    onClick={(e) => e.stopPropagation()}  // Prevent dropdown from closing on chip click
                                    style={{ margin: '2px' }}
                                />
                            ))}
                        </div>
                    )}
                    MenuProps={{
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                        },
                        transformOrigin: {
                            vertical: 'top',
                            horizontal: 'left',
                        },
                        getContentAnchorEl: null, // This makes sure the menu is always positioned below the input
                        PaperProps: {
                            style: {
                                maxHeight: 200, // Set maximum height
                                overflow: 'auto', // Add scroll if needed
                            },
                        },
                    }}
                >
                    {items.map((item) => (
                        <MenuItem key={item} value={item}>
                            <Checkbox checked={selectedItems.indexOf(item) > -1} />
                            <ListItemText primary={item} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            {selectedItems.length >= 3 && (
                <Typography color="error" style={{ marginTop: 10 }}>
                    You can choose a maximum of 3 resources.
                </Typography>
            )}
        </div>
    );
}

export default AdditionalResourcesCheckbox;
