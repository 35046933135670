export default function HeroSectionH() {
    return(
        <>
            {/* ====== Hero Section Start */}
            <div id="home" className="relative overflow-hidden bg-primary pt-[120px] md:pt-[130px] lg:pt-[160px]">
                <div className="container">
                    <div className="-mx-4 flex flex-wrap items-center">
                        <div className="w-full px-4">
                            <div className="hero-content wow fadeInUp mx-auto max-w-[780px] text-center" data-wow-delay=".2s">
                                <h1 className="mb-8 text-3xl font-bold leading-snug text-white sm:text-4xl sm:leading-snug md:text-[45px] md:leading-snug">
                                    Attention Homescholling Teachers <font color="black">Finally, Effortless Lesson Planning That actually Makes Learning Fun!</font>
                                </h1>
                                <p className="mx-auto mb-10 max-w-[600px] text-base text-[#e4e4e4] sm:text-lg sm:leading-relaxed md:text-xl md:leading-relaxed">
                                    Effortlessly Transform Your
                                    Homeschooling: Our AI Platform Creates Custom, Engaging Lesson Plans In Seconds, While You Focus on What Truly Matters.
                                </p>
                                <ul className="mb-10 flex flex-wrap items-center justify-center">
                                    <li>
                                        <a href="https://ailessonplan.com/signup" className="inline-flex items-center justify-center rounded-lg bg-white py-4 px-6 text-center text-base font-medium text-dark transition duration-300 ease-in-out hover:text-primary hover:shadow-lg sm:px-10">
                                            TRY IT FOR FREE
                                        </a>
                                    </li>
                                    <p className={"p-2 text-white"}>No Credit Card required.</p>
                                </ul>
                                {/*                              <div className="wow fadeInUp text-center" data-wow-delay=".3s">
                                  <img src="assets/images/hero/brand.svg" alt="image" className="mx-auto w-full max-w-[250px] opacity-50 transition duration-300 ease-in-out hover:opacity-100" />
                              </div>*/}
                            </div>
                        </div>
                        <div className="w-full px-4">
                            <div className="wow fadeInUp relative z-10 mx-auto max-w-[845px]" data-wow-delay=".25s">
                                {/*                              <div className="mt-16">
                                  <img src="assets/images/hero/hero-image.jpg" alt="hero" className="mx-auto max-w-full rounded-t-xl rounded-tr-xl" />
                              </div>*/}
                                {/*<div className="absolute bottom-0 -left-9 z-[-1]">
                                  <svg width={134} height={106} viewBox="0 0 134 106" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <circle cx="1.66667" cy={104} r="1.66667" transform="rotate(-90 1.66667 104)" fill="white" />
                                      <circle cx="16.3333" cy={104} r="1.66667" transform="rotate(-90 16.3333 104)" fill="white" />
                                      <circle cx={31} cy={104} r="1.66667" transform="rotate(-90 31 104)" fill="white" />
                                      <circle cx="45.6667" cy={104} r="1.66667" transform="rotate(-90 45.6667 104)" fill="white" />
                                      <circle cx="60.3333" cy={104} r="1.66667" transform="rotate(-90 60.3333 104)" fill="white" />
                                      <circle cx="88.6667" cy={104} r="1.66667" transform="rotate(-90 88.6667 104)" fill="white" />
                                      <circle cx="117.667" cy={104} r="1.66667" transform="rotate(-90 117.667 104)" fill="white" />
                                      <circle cx="74.6667" cy={104} r="1.66667" transform="rotate(-90 74.6667 104)" fill="white" />
                                      <circle cx={103} cy={104} r="1.66667" transform="rotate(-90 103 104)" fill="white" />
                                      <circle cx={132} cy={104} r="1.66667" transform="rotate(-90 132 104)" fill="white" />
                                      <circle cx="1.66667" cy="89.3333" r="1.66667" transform="rotate(-90 1.66667 89.3333)" fill="white" />
                                      <circle cx="16.3333" cy="89.3333" r="1.66667" transform="rotate(-90 16.3333 89.3333)" fill="white" />
                                      <circle cx={31} cy="89.3333" r="1.66667" transform="rotate(-90 31 89.3333)" fill="white" />
                                      <circle cx="45.6667" cy="89.3333" r="1.66667" transform="rotate(-90 45.6667 89.3333)" fill="white" />
                                      <circle cx="60.3333" cy="89.3338" r="1.66667" transform="rotate(-90 60.3333 89.3338)" fill="white" />
                                      <circle cx="88.6667" cy="89.3338" r="1.66667" transform="rotate(-90 88.6667 89.3338)" fill="white" />
                                      <circle cx="117.667" cy="89.3338" r="1.66667" transform="rotate(-90 117.667 89.3338)" fill="white" />
                                      <circle cx="74.6667" cy="89.3338" r="1.66667" transform="rotate(-90 74.6667 89.3338)" fill="white" />
                                      <circle cx={103} cy="89.3338" r="1.66667" transform="rotate(-90 103 89.3338)" fill="white" />
                                      <circle cx={132} cy="89.3338" r="1.66667" transform="rotate(-90 132 89.3338)" fill="white" />
                                      <circle cx="1.66667" cy="74.6673" r="1.66667" transform="rotate(-90 1.66667 74.6673)" fill="white" />
                                      <circle cx="1.66667" cy="31.0003" r="1.66667" transform="rotate(-90 1.66667 31.0003)" fill="white" />
                                      <circle cx="16.3333" cy="74.6668" r="1.66667" transform="rotate(-90 16.3333 74.6668)" fill="white" />
                                      <circle cx="16.3333" cy="31.0003" r="1.66667" transform="rotate(-90 16.3333 31.0003)" fill="white" />
                                      <circle cx={31} cy="74.6668" r="1.66667" transform="rotate(-90 31 74.6668)" fill="white" />
                                      <circle cx={31} cy="31.0003" r="1.66667" transform="rotate(-90 31 31.0003)" fill="white" />
                                      <circle cx="45.6667" cy="74.6668" r="1.66667" transform="rotate(-90 45.6667 74.6668)" fill="white" />
                                      <circle cx="45.6667" cy="31.0003" r="1.66667" transform="rotate(-90 45.6667 31.0003)" fill="white" />
                                      <circle cx="60.3333" cy="74.6668" r="1.66667" transform="rotate(-90 60.3333 74.6668)" fill="white" />
                                      <circle cx="60.3333" cy="31.0001" r="1.66667" transform="rotate(-90 60.3333 31.0001)" fill="white" />
                                      <circle cx="88.6667" cy="74.6668" r="1.66667" transform="rotate(-90 88.6667 74.6668)" fill="white" />
                                      <circle cx="88.6667" cy="31.0001" r="1.66667" transform="rotate(-90 88.6667 31.0001)" fill="white" />
                                      <circle cx="117.667" cy="74.6668" r="1.66667" transform="rotate(-90 117.667 74.6668)" fill="white" />
                                      <circle cx="117.667" cy="31.0001" r="1.66667" transform="rotate(-90 117.667 31.0001)" fill="white" />
                                      <circle cx="74.6667" cy="74.6668" r="1.66667" transform="rotate(-90 74.6667 74.6668)" fill="white" />
                                      <circle cx="74.6667" cy="31.0001" r="1.66667" transform="rotate(-90 74.6667 31.0001)" fill="white" />
                                      <circle cx={103} cy="74.6668" r="1.66667" transform="rotate(-90 103 74.6668)" fill="white" />
                                      <circle cx={103} cy="31.0001" r="1.66667" transform="rotate(-90 103 31.0001)" fill="white" />
                                      <circle cx={132} cy="74.6668" r="1.66667" transform="rotate(-90 132 74.6668)" fill="white" />
                                      <circle cx={132} cy="31.0001" r="1.66667" transform="rotate(-90 132 31.0001)" fill="white" />
                                      <circle cx="1.66667" cy="60.0003" r="1.66667" transform="rotate(-90 1.66667 60.0003)" fill="white" />
                                      <circle cx="1.66667" cy="16.3336" r="1.66667" transform="rotate(-90 1.66667 16.3336)" fill="white" />
                                      <circle cx="16.3333" cy="60.0003" r="1.66667" transform="rotate(-90 16.3333 60.0003)" fill="white" />
                                      <circle cx="16.3333" cy="16.3336" r="1.66667" transform="rotate(-90 16.3333 16.3336)" fill="white" />
                                      <circle cx={31} cy="60.0003" r="1.66667" transform="rotate(-90 31 60.0003)" fill="white" />
                                      <circle cx={31} cy="16.3336" r="1.66667" transform="rotate(-90 31 16.3336)" fill="white" />
                                      <circle cx="45.6667" cy="60.0003" r="1.66667" transform="rotate(-90 45.6667 60.0003)" fill="white" />
                                      <circle cx="45.6667" cy="16.3336" r="1.66667" transform="rotate(-90 45.6667 16.3336)" fill="white" />
                                      <circle cx="60.3333" cy="60.0003" r="1.66667" transform="rotate(-90 60.3333 60.0003)" fill="white" />
                                      <circle cx="60.3333" cy="16.3336" r="1.66667" transform="rotate(-90 60.3333 16.3336)" fill="white" />
                                      <circle cx="88.6667" cy="60.0003" r="1.66667" transform="rotate(-90 88.6667 60.0003)" fill="white" />
                                      <circle cx="88.6667" cy="16.3336" r="1.66667" transform="rotate(-90 88.6667 16.3336)" fill="white" />
                                      <circle cx="117.667" cy="60.0003" r="1.66667" transform="rotate(-90 117.667 60.0003)" fill="white" />
                                      <circle cx="117.667" cy="16.3336" r="1.66667" transform="rotate(-90 117.667 16.3336)" fill="white" />
                                      <circle cx="74.6667" cy="60.0003" r="1.66667" transform="rotate(-90 74.6667 60.0003)" fill="white" />
                                      <circle cx="74.6667" cy="16.3336" r="1.66667" transform="rotate(-90 74.6667 16.3336)" fill="white" />
                                      <circle cx={103} cy="60.0003" r="1.66667" transform="rotate(-90 103 60.0003)" fill="white" />
                                      <circle cx={103} cy="16.3336" r="1.66667" transform="rotate(-90 103 16.3336)" fill="white" />
                                      <circle cx={132} cy="60.0003" r="1.66667" transform="rotate(-90 132 60.0003)" fill="white" />
                                      <circle cx={132} cy="16.3336" r="1.66667" transform="rotate(-90 132 16.3336)" fill="white" />
                                      <circle cx="1.66667" cy="45.3336" r="1.66667" transform="rotate(-90 1.66667 45.3336)" fill="white" />
                                      <circle cx="1.66667" cy="1.66683" r="1.66667" transform="rotate(-90 1.66667 1.66683)" fill="white" />
                                      <circle cx="16.3333" cy="45.3336" r="1.66667" transform="rotate(-90 16.3333 45.3336)" fill="white" />
                                      <circle cx="16.3333" cy="1.66683" r="1.66667" transform="rotate(-90 16.3333 1.66683)" fill="white" />
                                      <circle cx={31} cy="45.3336" r="1.66667" transform="rotate(-90 31 45.3336)" fill="white" />
                                      <circle cx={31} cy="1.66683" r="1.66667" transform="rotate(-90 31 1.66683)" fill="white" />
                                      <circle cx="45.6667" cy="45.3336" r="1.66667" transform="rotate(-90 45.6667 45.3336)" fill="white" />
                                      <circle cx="45.6667" cy="1.66683" r="1.66667" transform="rotate(-90 45.6667 1.66683)" fill="white" />
                                      <circle cx="60.3333" cy="45.3338" r="1.66667" transform="rotate(-90 60.3333 45.3338)" fill="white" />
                                      <circle cx="60.3333" cy="1.66707" r="1.66667" transform="rotate(-90 60.3333 1.66707)" fill="white" />
                                      <circle cx="88.6667" cy="45.3338" r="1.66667" transform="rotate(-90 88.6667 45.3338)" fill="white" />
                                      <circle cx="88.6667" cy="1.66707" r="1.66667" transform="rotate(-90 88.6667 1.66707)" fill="white" />
                                      <circle cx="117.667" cy="45.3338" r="1.66667" transform="rotate(-90 117.667 45.3338)" fill="white" />
                                      <circle cx="117.667" cy="1.66707" r="1.66667" transform="rotate(-90 117.667 1.66707)" fill="white" />
                                      <circle cx="74.6667" cy="45.3338" r="1.66667" transform="rotate(-90 74.6667 45.3338)" fill="white" />
                                      <circle cx="74.6667" cy="1.66707" r="1.66667" transform="rotate(-90 74.6667 1.66707)" fill="white" />
                                      <circle cx={103} cy="45.3338" r="1.66667" transform="rotate(-90 103 45.3338)" fill="white" />
                                      <circle cx={103} cy="1.66707" r="1.66667" transform="rotate(-90 103 1.66707)" fill="white" />
                                      <circle cx={132} cy="45.3338" r="1.66667" transform="rotate(-90 132 45.3338)" fill="white" />
                                      <circle cx={132} cy="1.66707" r="1.66667" transform="rotate(-90 132 1.66707)" fill="white" />
                                  </svg>
                              </div>
                              <div className="absolute -top-6 -right-6 z-[-1]">
                                  <svg width={134} height={106} viewBox="0 0 134 106" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <circle cx="1.66667" cy={104} r="1.66667" transform="rotate(-90 1.66667 104)" fill="white" />
                                      <circle cx="16.3333" cy={104} r="1.66667" transform="rotate(-90 16.3333 104)" fill="white" />
                                      <circle cx={31} cy={104} r="1.66667" transform="rotate(-90 31 104)" fill="white" />
                                      <circle cx="45.6667" cy={104} r="1.66667" transform="rotate(-90 45.6667 104)" fill="white" />
                                      <circle cx="60.3333" cy={104} r="1.66667" transform="rotate(-90 60.3333 104)" fill="white" />
                                      <circle cx="88.6667" cy={104} r="1.66667" transform="rotate(-90 88.6667 104)" fill="white" />
                                      <circle cx="117.667" cy={104} r="1.66667" transform="rotate(-90 117.667 104)" fill="white" />
                                      <circle cx="74.6667" cy={104} r="1.66667" transform="rotate(-90 74.6667 104)" fill="white" />
                                      <circle cx={103} cy={104} r="1.66667" transform="rotate(-90 103 104)" fill="white" />
                                      <circle cx={132} cy={104} r="1.66667" transform="rotate(-90 132 104)" fill="white" />
                                      <circle cx="1.66667" cy="89.3333" r="1.66667" transform="rotate(-90 1.66667 89.3333)" fill="white" />
                                      <circle cx="16.3333" cy="89.3333" r="1.66667" transform="rotate(-90 16.3333 89.3333)" fill="white" />
                                      <circle cx={31} cy="89.3333" r="1.66667" transform="rotate(-90 31 89.3333)" fill="white" />
                                      <circle cx="45.6667" cy="89.3333" r="1.66667" transform="rotate(-90 45.6667 89.3333)" fill="white" />
                                      <circle cx="60.3333" cy="89.3338" r="1.66667" transform="rotate(-90 60.3333 89.3338)" fill="white" />
                                      <circle cx="88.6667" cy="89.3338" r="1.66667" transform="rotate(-90 88.6667 89.3338)" fill="white" />
                                      <circle cx="117.667" cy="89.3338" r="1.66667" transform="rotate(-90 117.667 89.3338)" fill="white" />
                                      <circle cx="74.6667" cy="89.3338" r="1.66667" transform="rotate(-90 74.6667 89.3338)" fill="white" />
                                      <circle cx={103} cy="89.3338" r="1.66667" transform="rotate(-90 103 89.3338)" fill="white" />
                                      <circle cx={132} cy="89.3338" r="1.66667" transform="rotate(-90 132 89.3338)" fill="white" />
                                      <circle cx="1.66667" cy="74.6673" r="1.66667" transform="rotate(-90 1.66667 74.6673)" fill="white" />
                                      <circle cx="1.66667" cy="31.0003" r="1.66667" transform="rotate(-90 1.66667 31.0003)" fill="white" />
                                      <circle cx="16.3333" cy="74.6668" r="1.66667" transform="rotate(-90 16.3333 74.6668)" fill="white" />
                                      <circle cx="16.3333" cy="31.0003" r="1.66667" transform="rotate(-90 16.3333 31.0003)" fill="white" />
                                      <circle cx={31} cy="74.6668" r="1.66667" transform="rotate(-90 31 74.6668)" fill="white" />
                                      <circle cx={31} cy="31.0003" r="1.66667" transform="rotate(-90 31 31.0003)" fill="white" />
                                      <circle cx="45.6667" cy="74.6668" r="1.66667" transform="rotate(-90 45.6667 74.6668)" fill="white" />
                                      <circle cx="45.6667" cy="31.0003" r="1.66667" transform="rotate(-90 45.6667 31.0003)" fill="white" />
                                      <circle cx="60.3333" cy="74.6668" r="1.66667" transform="rotate(-90 60.3333 74.6668)" fill="white" />
                                      <circle cx="60.3333" cy="31.0001" r="1.66667" transform="rotate(-90 60.3333 31.0001)" fill="white" />
                                      <circle cx="88.6667" cy="74.6668" r="1.66667" transform="rotate(-90 88.6667 74.6668)" fill="white" />
                                      <circle cx="88.6667" cy="31.0001" r="1.66667" transform="rotate(-90 88.6667 31.0001)" fill="white" />
                                      <circle cx="117.667" cy="74.6668" r="1.66667" transform="rotate(-90 117.667 74.6668)" fill="white" />
                                      <circle cx="117.667" cy="31.0001" r="1.66667" transform="rotate(-90 117.667 31.0001)" fill="white" />
                                      <circle cx="74.6667" cy="74.6668" r="1.66667" transform="rotate(-90 74.6667 74.6668)" fill="white" />
                                      <circle cx="74.6667" cy="31.0001" r="1.66667" transform="rotate(-90 74.6667 31.0001)" fill="white" />
                                      <circle cx={103} cy="74.6668" r="1.66667" transform="rotate(-90 103 74.6668)" fill="white" />
                                      <circle cx={103} cy="31.0001" r="1.66667" transform="rotate(-90 103 31.0001)" fill="white" />
                                      <circle cx={132} cy="74.6668" r="1.66667" transform="rotate(-90 132 74.6668)" fill="white" />
                                      <circle cx={132} cy="31.0001" r="1.66667" transform="rotate(-90 132 31.0001)" fill="white" />
                                      <circle cx="1.66667" cy="60.0003" r="1.66667" transform="rotate(-90 1.66667 60.0003)" fill="white" />
                                      <circle cx="1.66667" cy="16.3336" r="1.66667" transform="rotate(-90 1.66667 16.3336)" fill="white" />
                                      <circle cx="16.3333" cy="60.0003" r="1.66667" transform="rotate(-90 16.3333 60.0003)" fill="white" />
                                      <circle cx="16.3333" cy="16.3336" r="1.66667" transform="rotate(-90 16.3333 16.3336)" fill="white" />
                                      <circle cx={31} cy="60.0003" r="1.66667" transform="rotate(-90 31 60.0003)" fill="white" />
                                      <circle cx={31} cy="16.3336" r="1.66667" transform="rotate(-90 31 16.3336)" fill="white" />
                                      <circle cx="45.6667" cy="60.0003" r="1.66667" transform="rotate(-90 45.6667 60.0003)" fill="white" />
                                      <circle cx="45.6667" cy="16.3336" r="1.66667" transform="rotate(-90 45.6667 16.3336)" fill="white" />
                                      <circle cx="60.3333" cy="60.0003" r="1.66667" transform="rotate(-90 60.3333 60.0003)" fill="white" />
                                      <circle cx="60.3333" cy="16.3336" r="1.66667" transform="rotate(-90 60.3333 16.3336)" fill="white" />
                                      <circle cx="88.6667" cy="60.0003" r="1.66667" transform="rotate(-90 88.6667 60.0003)" fill="white" />
                                      <circle cx="88.6667" cy="16.3336" r="1.66667" transform="rotate(-90 88.6667 16.3336)" fill="white" />
                                      <circle cx="117.667" cy="60.0003" r="1.66667" transform="rotate(-90 117.667 60.0003)" fill="white" />
                                      <circle cx="117.667" cy="16.3336" r="1.66667" transform="rotate(-90 117.667 16.3336)" fill="white" />
                                      <circle cx="74.6667" cy="60.0003" r="1.66667" transform="rotate(-90 74.6667 60.0003)" fill="white" />
                                      <circle cx="74.6667" cy="16.3336" r="1.66667" transform="rotate(-90 74.6667 16.3336)" fill="white" />
                                      <circle cx={103} cy="60.0003" r="1.66667" transform="rotate(-90 103 60.0003)" fill="white" />
                                      <circle cx={103} cy="16.3336" r="1.66667" transform="rotate(-90 103 16.3336)" fill="white" />
                                      <circle cx={132} cy="60.0003" r="1.66667" transform="rotate(-90 132 60.0003)" fill="white" />
                                      <circle cx={132} cy="16.3336" r="1.66667" transform="rotate(-90 132 16.3336)" fill="white" />
                                      <circle cx="1.66667" cy="45.3336" r="1.66667" transform="rotate(-90 1.66667 45.3336)" fill="white" />
                                      <circle cx="1.66667" cy="1.66683" r="1.66667" transform="rotate(-90 1.66667 1.66683)" fill="white" />
                                      <circle cx="16.3333" cy="45.3336" r="1.66667" transform="rotate(-90 16.3333 45.3336)" fill="white" />
                                      <circle cx="16.3333" cy="1.66683" r="1.66667" transform="rotate(-90 16.3333 1.66683)" fill="white" />
                                      <circle cx={31} cy="45.3336" r="1.66667" transform="rotate(-90 31 45.3336)" fill="white" />
                                      <circle cx={31} cy="1.66683" r="1.66667" transform="rotate(-90 31 1.66683)" fill="white" />
                                      <circle cx="45.6667" cy="45.3336" r="1.66667" transform="rotate(-90 45.6667 45.3336)" fill="white" />
                                      <circle cx="45.6667" cy="1.66683" r="1.66667" transform="rotate(-90 45.6667 1.66683)" fill="white" />
                                      <circle cx="60.3333" cy="45.3338" r="1.66667" transform="rotate(-90 60.3333 45.3338)" fill="white" />
                                      <circle cx="60.3333" cy="1.66707" r="1.66667" transform="rotate(-90 60.3333 1.66707)" fill="white" />
                                      <circle cx="88.6667" cy="45.3338" r="1.66667" transform="rotate(-90 88.6667 45.3338)" fill="white" />
                                      <circle cx="88.6667" cy="1.66707" r="1.66667" transform="rotate(-90 88.6667 1.66707)" fill="white" />
                                      <circle cx="117.667" cy="45.3338" r="1.66667" transform="rotate(-90 117.667 45.3338)" fill="white" />
                                      <circle cx="117.667" cy="1.66707" r="1.66667" transform="rotate(-90 117.667 1.66707)" fill="white" />
                                      <circle cx="74.6667" cy="45.3338" r="1.66667" transform="rotate(-90 74.6667 45.3338)" fill="white" />
                                      <circle cx="74.6667" cy="1.66707" r="1.66667" transform="rotate(-90 74.6667 1.66707)" fill="white" />
                                      <circle cx={103} cy="45.3338" r="1.66667" transform="rotate(-90 103 45.3338)" fill="white" />
                                      <circle cx={103} cy="1.66707" r="1.66667" transform="rotate(-90 103 1.66707)" fill="white" />
                                      <circle cx={132} cy="45.3338" r="1.66667" transform="rotate(-90 132 45.3338)" fill="white" />
                                      <circle cx={132} cy="1.66707" r="1.66667" transform="rotate(-90 132 1.66707)" fill="white" />
                                  </svg>
                              </div>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* ====== Hero Section End */}
        </>
    );
}
