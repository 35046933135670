import * as React from "react";

function AboutUs() {
    return(
        <>
            {/* ====== About Section Start */}
            <section id="about" className="bg-[#f3f4fe] pt-20 pb-20 lg:pt-[120px] lg:pb-[120px]">
                <div className="container">
                    <div className="wow fadeInUp bg-white" data-wow-delay=".2s">
                        <div className="-mx-4 flex flex-wrap">
                            <div className="w-full px-4">
                                <div className="items-center justify-between overflow-hidden border lg:flex">
                                    <div className="w-full py-12 px-7 sm:px-12 md:p-16 lg:max-w-[565px] lg:py-9 lg:px-16 xl:max-w-[640px] xl:p-[70px]">
                      <span className="mb-5 inline-block bg-primary py-2 px-5 text-sm font-medium text-white">
                        About Us
                      </span>
                                        <h2 className="mb-6 text-3xl font-bold text-dark sm:text-4xl sm:leading-snug 2xl:text-[40px]">
                                            Artificial Intelligence Designed To Cut Hours Off Of A Teacher's Work Week.
                                        </h2>
                                        <p className="mb-9 text-base leading-relaxed text-body-color">
                                            Effortlessly generate engaging lesson plans and educational resources that your students will love, while consistently delivering learning objectives in a fraction of the time.
                                        </p>
                                        <p className="mb-9 text-base leading-relaxed text-body-color">
                                            Designing lesson plans that are perfectly tailored to your subject, grade-level, and even exact text book has never been easier. Pre-trained on the most common lesson plan formats for more efficiency. Don’t worry if you need a custom lesson plan layout, no problem, our AI has you covered.
                                        </p>
                                        <p className="mb-9 text-base leading-relaxed text-body-color">
                                            Not only will you be able to create a top notch comprehensive lesson plans, you'll be able to effortlessly create accompanying resources like rubrics, worksheets, homework assignments, assessments and more to match!
                                        </p>
                                        <a href="/signup" className="inline-flex items-center justify-center rounded bg-primary py-4 px-6 text-base font-medium text-white transition duration-300 ease-in-out hover:bg-opacity-90 hover:shadow-lg">
                                            Start Free Trial
                                        </a>
                                    </div>
                                    <div className="text-center">
                                        <video autoPlay muted loop onTouchStart={(e) => e.preventDefault()}>
                                            <source src="https://ailessonplan.com/images/welcome.mp4" type="video/mp4"/>
                                        </video>
                                    </div>
                                    {/*<div className="text-center">
                                        <video autoPlay muted loop onTouchStart={(e) => e.preventDefault()}>
                                            <source src="https://ailessonplan.com/images/welcome.mp4" type="video/mp4"/>
                                        </video>
                                    </div>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* ====== About Section End */}
        </>
    )
}

export default AboutUs;