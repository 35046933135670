import { Helmet } from "react-helmet";

function Header() {
  return (
    <>
      <Helmet>
        <meta charSet="UTF-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>AI - Lessons</title>
        <link rel="shortcut icon" href="/favicon.ico" type="image/x-icon" />
        <link rel="stylesheet" href="../../assets/css/animate.css" />
        <link rel="stylesheet" href="../../assets/css/tailwind.css" />
        <script src="../../assets/js/wow.min.js"></script>
        {/* <script>new WOW().init();</script> */}
        <script src="../../assets/js/main.js"></script>

        {/* <script src="../../assets/js/one_more.js"></script> */}
        <meta
          name="facebook-domain-verification"
          content="ws4ykc97b862yda19t1kff1sh9827o"
        />
      </Helmet>
      {/* ==== WOW JS ==== */}
    </>
  );
}

export default Header;
