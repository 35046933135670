import Header from "../components/LandingPage/Header";
import NavBar from "../components/LandingPage/NavBar";
import Features from "../components/LandingPage/Features";
import AboutUs from "../components/LandingPage/AboutUs";
import FAQ from "../components/LandingPage/FAQ";
import Testimonials from "../components/LandingPage/Testimonials";
import Contacts from "../components/LandingPage/Contacts";
import Footer from "../components/LandingPage/Footer";
import PricingTable from "../components/LandingPage/PricingTable";
import {useEffect} from "react";
import ReactGA from 'react-ga';
import {PricingTable2} from "../components/LandingPage/PricingTable2";
import HeroSectionH from "../components/HomeSchooling/HeroSectionH";
//import '../analytic/GoogleAnalytic'

function HomeSchooling() {
    useEffect(() => {
        // // Initialize the Facebook Pixel
        // ReactPixel.init('931037114604505');
        // // Track the PageView event
        // ReactPixel.pageView();

        const TRACKING_ID = 'G-M8647N6J28';
        ReactGA.initialize(TRACKING_ID);
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    return (
        <>
            <div>
                <Header/>
                <NavBar parent={"landingPage"}/>
                <HeroSectionH/>
                <Features/>
                <AboutUs/>
                {/*<PricingTable/>*/}
                <PricingTable2/>
                <FAQ/>
                <Testimonials/>
                {/*<TeamSection/>*/}
                <Contacts/>
                <Footer/>
                {/* ====== Back To Top Start */}
                <a href="" className="back-to-top fixed bottom-8 right-8 left-auto z-[999] hidden h-10 w-10 items-center justify-center rounded-md bg-primary text-white shadow-md transition duration-300 ease-in-out hover:bg-dark">
                    <span className="mt-[6px] h-3 w-3 rotate-45 border-t border-l border-white" />
                </a>
                {/* ====== Back To Top End */}
                {/* ====== All Scripts */}
            </div>
        </>
    );
}

export default HomeSchooling;